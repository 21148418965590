import React from "react";

import ErpFeaturesitem1 from "./ErpFeaturesitem1";
import ErpFeaturesitem2 from "./ErpFeaturesitem2";
import ErpFeaturesitem3 from "./ErpFeaturesitem3";
import ErpFeaturesitem4 from "./ErpFeaturesitem4";
import ErpFeaturesitem5 from "./ErpFeaturesitem5";

const ErpFeatures = () => {
  return (
    <section className="erp_features_area_two sec_pad">
      <div className="container">
        <ErpFeaturesitem1 rowClass="align-items-center flex-row-reverse" />
        <ErpFeaturesitem2 rowClass="align-items-center" />
        <ErpFeaturesitem3 rowClass="align-items-center flex-row-reverse" />
        <ErpFeaturesitem4 rowClass="align-items-center" />
        <ErpFeaturesitem5 rowClass="align-items-center flex-row-reverse" />
      </div>
    </section>
  );
};
export default ErpFeatures;
