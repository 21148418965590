import React from "react";
import { useRecoilState } from "recoil";
import Tabs from "../components/Multilink/Tabs";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
// import { trackEvent, PageEventName } from "../services/analytics";
import { getUrlByPath } from "../api/multilink";
import MultiLinkState from "../store/multilink";
import { trackEvent, PageEventName } from "../services/analytics";

const MultiLink = (props) => {
  const [content, setContent] = useRecoilState(MultiLinkState);
  const path = props.match.params.id;
  const isNew = path !== content.url;

  React.useEffect(() => {
    trackEvent(PageEventName.Multilink, { name: path });
  }, [path]);

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await getUrlByPath(path);
      if (result) {
        setContent(result);
        document
          .getElementById("og-title")
          .setAttribute("content", result.title);
        document.getElementById("og-desc").setAttribute("content", result.desc);
        if (result.icon) {
          document
            .getElementById("og-img")
            .setAttribute("content", result.icon);
        }
      } else {
        window.location.href = "/404";
      }
    };
    fetchData();
  }, [path, isNew, setContent]);

  if (path !== content.url) {
    return (
      <div className="flex_column div_centered">
        <lottie-player
          src="https://assets5.lottiefiles.com/packages/lf20_a2chheio.json"
          background="transparent"
          speed="1.2"
          style={{ width: 120 }}
          loop
          autoplay
        />
        <h2>Redirecting to your requested page</h2>
      </div>
    );
  }

  return (
    <>
      {!content.title ? (
        <div className="flex_column centered">
          <lottie-player
            src="https://assets5.lottiefiles.com/packages/lf20_a2chheio.json"
            background="transparent"
            speed="1.2"
            style={{ width: 120 }}
            loop
            autoplay
          />
          <h2>Redirecting to your requested page</h2>
        </div>
      ) : (
        <>
          <Tabs content={content} />
          <Footer FooterData={FooterData} />
        </>
      )}
    </>
  );
};

export default MultiLink;
