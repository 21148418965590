import React from "react";
import PaymentBanner from "../components/Banner/PaymentBanner";
import Admin from "../components/Features/Admin";
import Service from "../components/Service/Service";
import Subscribe from "../components/Subscribe";
import SecurityFeatures from "../components/Missions/SecurityFeatures";
import Video from "../components/Video/Video";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
import { trackEvent, PageEventName } from "../services/analytics";
import Associate from "../components/Features/Associate";
import EventPrice from "../components/EventPrice";
import EventFeatures from "../components/Event/EventFeatures";

export const Home = () => {
  React.useEffect(() => {
    trackEvent(PageEventName.Home);
  }, []);

  return (
    <div className="body_wrapper">
      <PaymentBanner />
      <EventFeatures />
      {/* <EventPrice /> */}
      <SecurityFeatures />
      <Video />
      {/* <Admin /> */}
      <Service />
      <Subscribe />
      {/* <div className="container mt-3 mb-3">
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-2141897620403515"
          data-ad-slot="6293601485"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </div> */}
      <Footer FooterData={FooterData} />
    </div>
  );
};
