import React from "react";
// import TrackingLogo from "../TrackingLogo";
// import Carousel from "react-material-ui-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const TrackingBanner = () => {
  return (
    <section className="tracking_banner_area text-center">
      <div className="container">
        <div className="tracking_banner_content">
          <h3>Bantu Menjana Perubahan Positif Belia Negara</h3>
          <p>EMPOWER | RESPECT | AWESOME</p>
          <div className="mb-5">
            <a
              href="#donateNow"
              variant="contained"
              className="btn_hover app_btn wow"
            >
              Sumbang Bulanan
            </a>
          </div>
          <div className="container1">
            <div className="app_screen_info">
              <Carousel
                className="app_screenshot_slider"
                autoPlay={true}
                interval="3000"
                transitionTime="1000"
                emulateTouch={true}
                infiniteLoop={true}
                showThumbs={false}
                showArrows={false}
                showStatus={false}
                showIndicators={false}
                dynamicHeight={false}
                axis={"horizontal"}
              >
                {/* <div className="item screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/main/10_tahun_gema.png")}
                    // width={490}
                    // height={360}
                    alt="c1"
                  />
                </div> */}
                <div className="item screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/main/heroes&green-heroes.png")}
                    // width={640}
                    styles={{ maxHeight: 360 }}
                    alt="c2"
                  />
                </div>
                <div className="item screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/main/-ve-heroes.png")}
                    // width={640}
                    // height={360}
                    alt="c3"
                  />
                </div>
                <div className="item screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/main/muzik-gema.png")}
                    // width={640}
                    // height={360}
                    alt="c4"
                  />
                </div>
                <div className="item screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/main/horizon.png")}
                    // width={640}
                    // height={360}
                    alt="c5"
                  />
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrackingBanner;
