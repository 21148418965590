import axios from "axios";

const token = process.env.REACT_APP_MAIN_API_TOKEN;

const mainAPIClient = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_MAIN_API_URL
      : process.env.REACT_APP_MAIN_API_URL_LOCAL,
  headers: {
    "X-Request-Agent": "gema-web",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export async function enrollForEvent(isMember, eventId, values, customValues) {
  try {
    if (isMember) {
      const { data } = await mainAPIClient.post(`/enrollment/member`, {
        eventId,
        nationalId: values.nationalId,
        customFields: customValues,
      });
      return data;
    } else {
      const { data } = await mainAPIClient.post(`/enrollment/associate`, {
        eventId,
        fullname: values.fullname,
        phone: values.phone,
        email: values.email,
        branch: values.branch,
        nationalId: values.nationalId,
        customFields: customValues,
      });
      return data;
    }
  } catch (error) {
    throw error.response.data;
  }
}

export async function getEventByStatus(qs) {
  try {
    const { data } = await mainAPIClient.get(`/event?status=${qs}`);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getEventByEventId(id) {
  try {
    const { data } = await mainAPIClient.get(`/event/eventId/${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function completeEnrollment(id) {
  try {
    const { data } = await mainAPIClient.patch(`/enrollment/${id}`, {
      enrollmentStatus: 1,
    });
    return data;
  } catch (error) {
    console.log(error);
  }
}
