import React, { useState } from "react";
import { Formik } from "formik";
import { TextField, CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { registerVolunteer } from "../../api/msf";
import {
  states,
  genders,
  races,
  religions,
  education,
} from "../../utils/Constant";

const VolunteerForm = () => {
  const [success, setSuccess] = useState(false);
  const [msg, setMsg] = useState("");

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };

  return (
    <section className="sign_in_area bg_color sec_pad">
      <div className="container text-center mb-5">
        <h4>Borang pendaftaran sukarelawan yang berminat</h4>
        <p style={{ color: "red" }}>{msg}</p>
        <Formik
          initialValues={{
            name: "",
            nationalId: "",
            email: "",
            phone: "",
            address: "",
            state: "",
            gender: "",
            race: "",
            age: "",
            religion: "",
            education: "",
            occupation: "",
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            const { created, data } = await registerVolunteer(values);
            setSubmitting(false);
            if (created) {
              setSuccess(true);
              setTimeout(() => {
                window.location.href =
                  "https://whatsapp.com/channel/0029VaNoL66ATRSqIcAw6b3n";
              }, 2000);
            } else {
              setSuccess(false);
              setMsg(data.message || "Something went wrong");
              setTimeout(() => {
                setMsg("");
              }, 3000);
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="col-12">
                <TextField
                  label="Nama Penuh"
                  margin="normal"
                  type="text"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  variant="outlined"
                  fullWidth
                  required
                />
              </div>
              <div className="col-12">
                <TextField
                  label="No. Kad Pengenalan"
                  margin="normal"
                  type="text"
                  name="nationalId"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.nationalId}
                  variant="outlined"
                  fullWidth
                  required
                />
              </div>
              <div className="col-12">
                <TextField
                  label="E-mel"
                  margin="normal"
                  type="text"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  variant="outlined"
                  fullWidth
                  required
                />
              </div>
              <div className="col-12">
                <TextField
                  label="No telefon"
                  margin="normal"
                  type="text"
                  name="phone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone}
                  variant="outlined"
                  fullWidth
                  required
                />
              </div>
              <div className="col-12">
                <Autocomplete
                  options={genders}
                  getOptionLabel={(option) => option}
                  name="gender"
                  onChange={(_e, val) => {
                    setFieldValue("gender", val);
                  }}
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Jantina"
                      margin="normal"
                      variant="outlined"
                      value={values.gender}
                      required
                      fullWidth
                    />
                  )}
                />
              </div>
              <div className="col-12">
                <TextField
                  label="Umur"
                  margin="normal"
                  type="number"
                  name="age"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.age}
                  variant="outlined"
                  fullWidth
                  required
                />
              </div>
              <div className="col-12">
                <Autocomplete
                  options={races}
                  getOptionLabel={(option) => option}
                  onChange={(_e, val) => {
                    setFieldValue("race", val);
                  }}
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Bangsa"
                      margin="normal"
                      variant="outlined"
                      value={values.race}
                      required
                      fullWidth
                    />
                  )}
                />
              </div>
              <div className="col-12">
                <Autocomplete
                  options={religions}
                  getOptionLabel={(option) => option}
                  onChange={(_e, val) => {
                    setFieldValue("religion", val);
                  }}
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Agama"
                      margin="normal"
                      variant="outlined"
                      value={values.religion}
                      required
                      fullWidth
                    />
                  )}
                />
              </div>
              <div className="col-12">
                <Autocomplete
                  options={education}
                  getOptionLabel={(option) => option}
                  onChange={(_e, val) => {
                    setFieldValue("education", val);
                  }}
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Taraf Pendidikan"
                      margin="normal"
                      variant="outlined"
                      value={values.education}
                      required
                      fullWidth
                    />
                  )}
                />
              </div>
              <div className="col-12">
                <TextField
                  label="Pekerjaan (Isi Pelajar sekiranya masih belajar)"
                  margin="normal"
                  type="text"
                  name="occupation"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.occupation}
                  variant="outlined"
                  fullWidth
                  required
                />
              </div>
              <div className="col-12">
                <TextField
                  label="Kawasan Tempat Tinggal Anda"
                  margin="normal"
                  type="text"
                  name="address"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.address}
                  variant="outlined"
                  fullWidth
                  required
                />
              </div>
              <div className="col-12 mt-2 mb-4">
                <Autocomplete
                  options={states}
                  getOptionLabel={(option) => option}
                  onChange={(_e, val) =>
                    setFieldValue("state", val ? val : undefined)
                  }
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Negeri operasi MSF yang akan anda hadir"
                      variant="outlined"
                      value={values.state}
                      fullWidth
                      required
                    />
                  )}
                />
              </div>
              <div className="col-12 text-center">
                {!isSubmitting ? (
                  <>
                    <button
                      type="submit"
                      variant="contained"
                      className="seo_btn seo_btn_one btn_hover wow"
                    >
                      Hantar Maklumat
                    </button>
                    <p className="mt-3">
                      Dengan menghantar maklumat, anda akan dihubungi untuk info
                      lanjut tentang Operasi MySaveFood@BazarRamadan.
                    </p>
                  </>
                ) : (
                  <CircularProgress />
                )}
              </div>
            </form>
          )}
        </Formik>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={success}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
      >
        <MuiAlert onClose={handleCloseSuccess} severity="success">
          Pendaftaran Berjaya! Anda akan ke laman WhatsApp untuk info lanjut.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={msg.length > 0 && !success}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
      >
        <MuiAlert onClose={handleCloseSuccess} severity="error">
          Registration Failed: {msg}!
        </MuiAlert>
      </Snackbar>
    </section>
  );
};

export default VolunteerForm;
