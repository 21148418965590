import React from "react";
const EventPrice = () => {
  return (
    <section className="event_price_area sec_pad">
      <div className="container">
        <div className="hosting_title security_title text-center">
          <h2>Perbandingan keahlian Pemuda GEMA</h2>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-4 col-sm-6">
            <div className="analytices_price_item event_price_item wow fadeInUp">
              <div className="p_head">
                <h5>Rakan GEMA</h5>
                <div className="rate">RM0.00</div>
              </div>
              <ul className="list-unstyled p_body">
                <li>
                  Tiada Bayaran<i className="ti-check"></i>
                </li>
                <li>
                  Penglibatan Hanya Sebagai Sukarelawan
                  <i className="ti-check"></i>
                </li>
                <li>
                  Tidak boleh menyertai program untuk ahli
                  <i className="ti-check"></i>
                </li>
              </ul>
              <div className="text-center">
                <a
                  href="/associate"
                  className="event_btn event_btn_two btn_hover"
                >
                  Daftar
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div
              className="analytices_price_item event_price_item active wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <div className="p_head">
                <h5>Ahli Penuh</h5>
                <h6 className="tag">
                  <i className="icon_ribbon_alt"></i>popular
                </h6>
                <div className="rate">RM55.00</div>
              </div>
              <ul className="list-unstyled p_body">
                <li>
                  Bayaran Sekali Sepanjang Hayat <i className="ti-check"></i>
                </li>
                <li>
                  Penggerak & AJK program <i className="ti-check"></i>
                </li>
                <li>
                  Modul Latihan & Kepimpinan<i className="ti-check"></i>
                </li>
                <li>
                  Tempoh Keahlian hingga umur 35<i className="ti-check"></i>
                </li>
              </ul>
              <div className="text-center">
                <a
                  href="/register?membership=1"
                  className="event_btn btn_hover"
                >
                  Daftar
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div
              className="analytices_price_item event_price_item wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <div className="p_head">
                <h5>Ahli Asas</h5>
                <div className="rate">RM10.00</div>
              </div>
              <ul className="list-unstyled p_body">
                <li>
                  Bayaran Setiap Tahun <i className="ti-check"></i>
                </li>
                <li>
                  Penggerak & AJK program <i className="ti-check"></i>
                </li>
                <li>
                  Modul Latihan & Kepimpinan<i className="ti-check"></i>
                </li>
                <li>
                  Tempoh Keahlian Hanya Setahun<i className="ti-check"></i>
                </li>
              </ul>
              <div className="text-center">
                <a
                  href="/register"
                  className="event_btn event_btn_two btn_hover"
                >
                  Daftar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default EventPrice;
