import React from "react";
import SecurityFeaturesItem from "./SecurityFeaturesItem";

const SecurityFeatures = () => {
  return (
    <section className="h_security_area">
      <div className="container">
        <div className="hosting_title security_title text-center">
          <h2 className="wow fadeInUp" data-wow-delay="0.2s">
            Matlamat Penubuhan <strong>Pemuda GEMA</strong>
          </h2>
        </div>
        <div className="row">
          <SecurityFeaturesItem
            itemClass="pr_70"
            image="security_3.png"
            Title="Memupuk Semangat Perpaduan"
            pText="Mengumpulkan anak muda Malaysia di atas dasar perpaduan serta membina keperibadian diri yang baik, berhemah tinggi, moderat dan progresif."
          />
          <SecurityFeaturesItem
            itemClass="pl_70"
            image="security_2.png"
            Title="Membangun Modal Insan"
            pText="Membantu meninggikan taraf pendidikan dan sosio-ekonomi anak muda di Malaysia bagi mewujudkan masyarakat yang makmur dan sejahtera."
          />
          <SecurityFeaturesItem
            itemClass="pr_70"
            image="security_4.png"
            Title="Memelihara Kesejahteraan Sosial"
            pText="Memupuk keadilan dan kesejahteraan sosial serta membenteras kejahilan, kemiskinan dan keruntuhan moral di kalangan masyarakat."
          />
          <SecurityFeaturesItem
            itemClass="pl_70"
            image="security_1.png"
            Title="Mengukuhkan kerjasama"
            pText="Menjalinkan hubungan dan kerjasama dengan pihak yang berkongsi aspirasi dan matlamat Pemuda GEMA bagi memajukan kepentingan masyarakat dan negara."
          />
        </div>
      </div>
    </section>
  );
};
export default SecurityFeatures;
