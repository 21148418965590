import React, { useState } from "react";
import { TextField, CircularProgress } from "@material-ui/core";
import { Chart } from "chart.js";
import moment from "moment";
import { Link } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
import { findVolunteers } from "../api/msf";
import { generateRandomColors } from "../utils/colors";
import { bazarList } from "../utils/Constant";
import { trackEvent, PageEventName } from "../services/analytics";

const MsfVolunteer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedBazar, setSelectedBazar] = useState(0);
  const [volunteersByDate, setVolunteersByDate] = useState([]);
  const [volunteersByBazar, setVolunteersByBazar] = useState([]);
  const [volunteersAttended, setvolunteersAttended] = useState([]);
  const [uniqueVolunteersCount, setUniqueVolunteersCount] = useState(0);

  React.useEffect(() => {
    trackEvent(PageEventName.MsfVolunteer);
  }, []);

  React.useEffect(() => {
    setTimeout(() => setIsLoading(false), 3000);
  }, []);

  React.useEffect(() => {
    const bazars = bazarList
      .map((b) => b.value)
      .filter((b) => b !== "Bazar Ramadan GEMA");
    const fetchCollection = async () => {
      const { data, mapped, uniqueCount } = await findVolunteers(bazars);
      if (mapped) {
        setVolunteersByDate(mapped);
        setvolunteersAttended(mapped.reduce((a, b) => a + b.count, 0));
        setUniqueVolunteersCount(uniqueCount);
      }
      if (data) {
        setVolunteersByBazar(
          data.filter(
            (m) => m.attendedCount > 0 && m.name !== "Bazar Ramadan GEMA"
          )
        );
      }
    };
    fetchCollection();
  }, []);

  React.useEffect(() => {
    const colors = generateRandomColors(volunteersByDate.length);
    const ct = document.getElementById("dayChart");
    if (ct) {
      new Chart(ct, {
        type: "bar",
        data: {
          labels: volunteersByDate.map((t) => t.date),
          datasets: [
            {
              label: "Jumlah Sukarelawan (Orang)",
              data: volunteersByDate.map((t) => t.count),
              backgroundColor: colors,
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Jumlah (Orang)",
                },
              },
            ],
          },
        },
      });
    }
  }, [volunteersByDate]);

  React.useEffect(() => {
    const colors = generateRandomColors(volunteersByBazar.length);
    const ct = document.getElementById("bazarChart");
    if (ct) {
      new Chart(ct, {
        type: "bar",
        data: {
          labels: volunteersByBazar.map((t) => t.name),
          datasets: [
            {
              label: "Jumlah Sukarelawan (Orang)",
              data: volunteersByBazar.map((t) => t.attendedCount),
              backgroundColor: colors,
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Jumlah (Orang)",
                },
              },
            ],
          },
        },
      });
    }
  }, [volunteersByBazar]);

  return (
    <div className="body_wrapper">
      <div className="event_img align-items-right">
        <img
          width="100%"
          src={require("../img/attend/mysavefood2.jpeg")}
          alt=""
        />
      </div>
      <div>
        <div className="container text-center mt-3 mb-3">
          <div className="row">
            <div className="col-md-4">
              <Link to="/collection/msf">Maklumat Kutipan</Link>
            </div>
            <div className="col-md-4">
              <Link to="/volunteer/msf">Maklumat Sukarelawan</Link>
            </div>
            <div className="col-md-4">
              <Link to="/map/msf">Peta Kutipan</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mb-5 mt-5">
        <h6 className="mb-3">
          Kehadiran Sukarelawan Setakat {moment().format("DD MMMM yy")}
        </h6>
        {isLoading && (
          <>
            <CircularProgress />
            <p>Please wait while charts are loading...</p>
          </>
        )}
      </div>
      <div
        className="container text-center mb-5"
        style={{ opacity: isLoading ? 0.1 : 1 }}
      >
        <div>
          <span>Jumlah masa yang diluangkan adalah sebanyak</span>
          <span>
            {volunteersAttended && (
              <h1 className="mt-3">
                {volunteersAttended * 3} <span>JAM</span>
              </h1>
            )}
          </span>
          <span>
            oleh <h3 className="mb-5">{uniqueVolunteersCount} sukarelawan</h3>
          </span>
          <p className="mt-5">Kehadiran Sukarelawan Mengikut Hari</p>
          <canvas id="dayChart" />
          <p className="mt-5">Kehadiran Sukarelawan Mengikut Bazar</p>
          <canvas id="bazarChart" />
        </div>
        <div className="mb-3">
          <Autocomplete
            options={bazarList}
            getOptionLabel={(option) => option.title}
            onChange={(_e, val) => setSelectedBazar(val.value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Lokasi Bazar"
                margin="normal"
                variant="outlined"
                value={selectedBazar}
                required
                fullWidth
              />
            )}
          />
        </div>
        <div>
          {volunteersByBazar &&
            volunteersByBazar.length > 0 &&
            volunteersByBazar.map((bz, i) => {
              return (
                selectedBazar === bz.name && (
                  <div key={i}>
                    <h5>
                      {i + 1}. {bz.name}
                    </h5>
                    <div className="row">
                      <div className="col-12">
                        <table
                          style={{ fontSize: 14 }}
                          className="table table-bordered"
                        >
                          <thead>
                            <tr className="text-center">
                              <td>Tarikh Operasi</td>
                              <td>Bilangan Yang Hadir (Orang)</td>
                            </tr>
                          </thead>
                          <tbody>
                            {bz.attendeeByDate &&
                              bz.attendeeByDate.map(({ date, count }) => (
                                <tr className="text-center">
                                  <td>{date}</td>
                                  <td>{count}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="col-12">
                        <h6>Jumlah: {bz.attendedCount}</h6>
                      </div>
                    </div>
                  </div>
                )
              );
            })}
        </div>
      </div>
      <div className="text-center">
        <h3 className="mb">
          Jutaan terima kasih kepada semua sukarelawan yang hadir
        </h3>
        {/* <p>
          Masih belum terlambat untuk yang lain menyertai <br />
          Daftar disini untuk tindakan selanjutnya:
          <Link to="/event?id=97"> Sukarelawan MySaveFood</Link> <br />
        </p> */}
        <p>Ayuh bersama kami selamatkan makanan dari pembaziran!</p>
      </div>
      <div>
        <img width="100%" src={require("../img/attend/volunteer.svg")} alt="" />
      </div>
      <Footer FooterData={FooterData} />
    </div>
  );
};

export default MsfVolunteer;
