import axios from "axios";

const token = process.env.REACT_APP_MAIN_API_TOKEN;

const mainAPIClient = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_MAIN_API_URL
      : process.env.REACT_APP_MAIN_API_URL_LOCAL,
  headers: {
    "X-Request-Agent": "gema-web",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export async function createNewAssociate(associateDto) {
  try {
    const response = await mainAPIClient.post("/associate", associateDto);
    if (response.status === 201) {
      return true;
    }
    return false;
  } catch (error) {
    throw error.response.data;
  }
}
