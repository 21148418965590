import axios from "axios";

const urlAxios = axios.create({
  baseURL:
    process.env.REACT_APP_SUBSCRIPTION_SERVICE_URL || "http://localhost:8080",
});

export async function createSub(payload) {
  try {
    const { data } = await urlAxios.post(`/recurring-donation`, {
      ...payload,
      amount: Number(payload.amount.toString() + "00"),
    });
    return data;
  } catch (error) {
    console.log(error);
  }
}
