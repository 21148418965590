import React, { useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const initialNames = ["John Doe", "Jane Doe", "John Smith"];

const Certificate = () => {
  const certificateRefs = useRef([]);
  const [names, _setNames] = useState(initialNames); // Replace with your names

  const generatePDFs = () => {
    names.forEach((name, index) => {
      html2canvas(certificateRefs.current[index], {
        scrollY: -window.scrollY,
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "px",
          format: [canvas.width / 5, canvas.height / 5],
        });
        pdf.addImage(
          imgData,
          "PNG",
          0,
          0,
          canvas.width / 5,
          canvas.height / 5,
          undefined,
          "FAST"
        );
        pdf.save(name + ".pdf");
      });
    });
  };

  return (
    <div>
      <div className="text-center">
        <button onClick={generatePDFs}>Generate PDFs</button>
      </div>
      {names.map((name, index) => (
        <div ref={(el) => (certificateRefs.current[index] = el)}>
          <Cert name={name} ic={"123456789"} />
        </div>
      ))}
    </div>
  );
};

export default Certificate;

const Cert = ({ name, ic }) => {
  return (
    <div className="container2">
      <div
        style={{
          backgroundImage:
            "url(" + require("../img/template/plogging.png") + ")",
          backgroundSize: "contain",
          width: 1272,
          height: 1800,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            paddingTop: "39rem",
          }}
        >
          <p
            style={{
              fontSize: "43pt",
              fontFamily: "Quattrocento",
              color: "#1B3D49",
              marginBottom: 30,
            }}
          >
            {name}
          </p>
          {/* <p
            style={{
              fontSize: "39pt",
              fontFamily: "Quattrocento",
              color: "#1B3D49",
            }}
          >
            {ic}
          </p> */}
        </div>
      </div>
    </div>
  );
};
