import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import Reveal from "react-reveal";
import { Redirect } from "react-router-dom";
import { TextField, CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { branch } from "../utils/Constant";
import Sectitle from "../components/Title/Sectitle";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
import { createNewAssociate } from "../api/associate";
import {
  ActionEventName,
  ButtonEventName,
  PageEventName,
  trackEvent,
} from "../services/analytics";

export default function Associate() {
  const [success, setSuccess] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [failure, setFailure] = useState(false);
  const [error, setError] = useState("Registartion Failed!");

  useEffect(() => {
    trackEvent(PageEventName.Associate);
  }, []);

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess(false);
  };
  const handleCloseFailure = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setFailure(false);
  };

  if (redirect && !success) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }
  return (
    <div>
      <section id="form" className="bg_color">
        <Sectitle
          Title="Pendaftaran Rakan GEMA"
          TitleP="Ruangan dengan tanda * wajib diisi."
          tClass="t_color3"
          sClass="sec_title text-center mt_30 mb_40"
        />
        <div className="container custom_container">
          <div className="startup_tab_img">
            <Reveal effect="fadeInLeft">
              <div className="row">
                <div className="col-12">
                  {success ? (
                    <div className="col-md-6 offset-md-3 col-sm-12 text-center">
                      <img
                        className="mh_50"
                        src={require("../img/utils/sucess.png")}
                        alt=""
                        width="250"
                      />
                      <h4>Terima Kasih! Permohonan anda telah diterima!</h4>
                    </div>
                  ) : (
                    <Formik
                      initialValues={{}}
                      onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(true);
                        trackEvent(ButtonEventName.AssociateRegisterSubmission);
                        createNewAssociate(values)
                          .then((result) => {
                            if (result) {
                              setSuccess(true);
                              trackEvent(
                                ActionEventName.ASSOCIATE_REGISTER_COMPLETED
                              );
                              setSubmitting(false);
                              return setTimeout(() => {
                                setRedirect(true);
                              }, 2000);
                            }
                            setFailure(true);
                            setSubmitting(false);
                          })
                          .catch((err) => {
                            setError(err.message || "Failed");
                            setFailure(true);
                            setSubmitting(false);
                          });
                      }}
                    >
                      {({
                        values,
                        setFieldValue,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="text-left">
                            <div className="col-md-6 offset-md-3 col-sm-12">
                              <TextField
                                label="Nama Penuh"
                                margin="normal"
                                type="text"
                                name="fullname"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.fullname}
                                variant="outlined"
                                fullWidth
                                required
                              />
                            </div>
                            <div className="col-md-6 offset-md-3 col-sm-12">
                              <TextField
                                label="No. Kad Pengenalan"
                                margin="normal"
                                type="number"
                                name="nationalId"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.nationalId}
                                variant="outlined"
                                fullWidth
                                required
                              />
                            </div>
                            <div className="col-md-6 offset-md-3 col-sm-12">
                              <Autocomplete
                                options={branch}
                                getOptionLabel={(option) => option.title}
                                onChange={(_e, val) =>
                                  setFieldValue(
                                    "branch",
                                    val ? val.value : undefined
                                  )
                                }
                                onBlur={handleBlur}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Cawangan Gema"
                                    margin="normal"
                                    variant="outlined"
                                    value={values.branch}
                                    required
                                    fullWidth
                                  />
                                )}
                              />
                            </div>
                            <div className="col-md-6 offset-md-3 col-sm-12">
                              <TextField
                                label="Email"
                                margin="normal"
                                fullWidth
                                type="text"
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                variant="outlined"
                                required
                              />
                            </div>
                            <div className="col-md-6 offset-md-3 col-sm-12">
                              <TextField
                                label="No HP"
                                margin="normal"
                                fullWidth
                                type="number"
                                name="phone"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.phone}
                                variant="outlined"
                                required
                              />
                            </div>
                            <div className="col-md-6 offset-md-3 col-sm-12">
                              <TextField
                                label="Profil Facebook"
                                margin="normal"
                                fullWidth
                                type="text"
                                name="fbProfile"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.fbProfile}
                                variant="outlined"
                              />
                            </div>
                            <div className="col-md-6 offset-md-3 col-sm-12">
                              <TextField
                                label="Profil Instagram"
                                margin="normal"
                                fullWidth
                                type="text"
                                name="iGProfile"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.iGProfile}
                                variant="outlined"
                              />
                            </div>
                            <div className="col-md-6 offset-md-3 col-sm-12">
                              <TextField
                                label="Profil Tiktok"
                                margin="normal"
                                fullWidth
                                type="text"
                                name="tikTokProfile"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.tikTokProfile}
                                variant="outlined"
                              />
                            </div>
                          </div>
                          <div className="col-md-4 offset-md-4 col-sm-12">
                            <div className="verify_button_container">
                              {!isSubmitting ? (
                                <button
                                  type="submit"
                                  className="seo_btn seo_btn_two btn_hover wow mv_10"
                                >
                                  Hantar
                                </button>
                              ) : (
                                <CircularProgress color="secondary" />
                              )}

                              <a
                                href="/"
                                className="seo_btn seo_btn_three btn_hover wow mv_10"
                              >
                                Kembali
                              </a>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  )}
                </div>
              </div>
            </Reveal>
            <Reveal effect="fadeInRight">
              <div className="phone_img">
                <img
                  style={{ opacity: 0.2 }}
                  src={require("../img/home4/work2.png")}
                  alt="associate"
                />
              </div>
            </Reveal>
          </div>
        </div>
      </section>
      <Footer FooterData={FooterData} />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={success}
        autoHideDuration={2000}
        onClose={handleCloseSuccess}
      >
        <MuiAlert onClose={handleCloseSuccess} severity="success">
          Pendaftaran Berjaya!
        </MuiAlert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={failure}
        autoHideDuration={4000}
        onClose={handleCloseFailure}
      >
        <MuiAlert onClose={handleCloseFailure} severity="error">
          {error}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
