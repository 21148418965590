import React from "react";

const Summary = () => {
  return (
    <section className="sign_in_area bg_color sec_pad">
      {/* <div className="container text-center bg_color_2 p-5 mb-5">
        <h5>
          <strong>TERBARU!!</strong> Kami sedang memulakan projek{" "}
          <strong>MySaveFood@Keramat Mall</strong> pada bulan Oktober. <br />{" "}
          Peniaga yang berminat boleh mengisi maklumat pada{" "}
          <Link to="keramat/msf">halaman ini</Link>
        </h5>
        <button
          onClick={() => (window.location.href = "/keramat/msf")}
          className="btn_three sign_btn_transparent "
          target="_blank"
        >
          Sertai MySaveFood@Keramat Mall
        </button>
      </div> */}
      <div className="container text-center mb-5">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <p>
              Projek <strong>MYSaveFood@Ramadan</strong> merupakan satu
              inisiatif pengurusan sisa makanan Food Waste Management di bazar
              Ramadan secara berstrategi melalui usaha menyelamatkan lebihan
              makanan dan minuman yang tidak berjaya dijual oleh peniaga bazar
              dan diagihkan kepada golongan yang memerlukan.
              <br />
              <br />
              Inisiatif ini selaras dengan{" "}
              <strong>
                "Matlamat Pembangunan Mampan/Sustainable Development Goals"
                (SDG) 12.3
              </strong>{" "}
              iaitu pengurangan separuh daripada sisa makanan global per kapita
              pada tahun 2030.
            </p>
          </div>
          <div className="col-md-6 col-sm-12">
            <img
              className="mt-3"
              width="100%"
              src={require("../../img/attend/process.png")}
              alt="msf-process"
            />
            <span>Proses Aktiviti MySaveFood</span>
          </div>
        </div>
        <button
          onClick={() => (window.location.href = "/collection/msf")}
          className="btn_three sign_btn_transparent "
          target="_blank"
        >
          Lihat Kutipan Terkini MySaveFood@Ramadan 2024
        </button>
      </div>
      <div className="container text-center">
        <h3 className="mb-5 mt-3">Sertai MySaveFood@Ramadan 2024</h3>
        <div className="sign_info">
          <div className="row">
            <div className="col-lg-5">
              <div className="sign_info_content text-center">
                <img
                  width="100%"
                  src={require("../../img/attend/2024.jpg")}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="text-center">
                <button
                  onClick={() => (window.location.href = "/registration/msf")}
                  type="submit"
                  className="btn_three sign_btn_transparent"
                >
                  Pendaftaran Sukarelawan MSF 2024
                </button>
                <br />
                <button
                  onClick={() =>
                    (window.location.href = "registration/msf?role=1")
                  }
                  type="submit"
                  className="btn_three sign_btn_transparent"
                >
                  Pendaftaran Ketua Bazar MSF 2024
                </button>
                <br />
                <button
                  onClick={() => (window.location.href = "/pdf/msf23-report")}
                  className="btn_three sign_btn_transparent"
                  type="submit"
                >
                  Lihat Laporan Penuh MySaveFood@Ramadhan 2023
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 text-center">
        <h3 className="mb-5">Liputan Media MySaveFood@Ramadan 2023</h3>
        <div className="video_content">
          <div className="video_info mb-5">
            <div className="ovarlay_color"></div>
            <iframe
              title="youtube"
              width="640"
              height="360"
              src="https://www.youtube.com/embed/LoefQ7dbTO0"
              frameBorder="0"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <div className="video_info mb-5">
            <div className="ovarlay_color"></div>
            <iframe
              title="youtube"
              width="640"
              height="360"
              src="https://www.youtube.com/embed/2gfDxepF0h8"
              frameBorder="0"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <div className="video_info mb-5">
            <div className="ovarlay_color"></div>
            <iframe
              title="youtube"
              width="640"
              height="360"
              src="https://www.youtube.com/embed/7bpyelIKAMY"
              frameBorder="0"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <img
            className="video_leaf"
            src={require("../../img/new/leaf.png")}
            alt=""
          />
          <img className="cup" src={require("../../img/new/cup.png")} alt="" />
        </div>
      </div>
    </section>
  );
};
export default Summary;
