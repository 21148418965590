import React, { useState } from "react";
import { Formik } from "formik";
import { TextField, CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { createNewLeader } from "../../api/msf";
import { states } from "../../utils/Constant";

const CaptainForm = () => {
  const [success, setSuccess] = useState(false);
  const [msg, setMsg] = useState("");

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };

  return (
    <section className="sign_in_area bg_color sec_pad">
      <div className="container text-center mb-5">
        <h4>Pendaftaran Ketua Negeri untuk Akaun Akses</h4>
        <p style={{ color: "red" }}>{msg}</p>
        <Formik
          initialValues={{
            name: "",
            nationalId: "",
            phone: "",
            email: "",
            password: "",
            organisation: "",
            role: 0,
            state: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Nama diperlukan";
            }
            if (!values.nationalId) {
              errors.nationalId = "No kad Pengenalan diperlukan";
            }
            if (!values.phone) {
              errors.phone = "No telefon diperlukan";
            }
            if (!values.email) {
              errors.email = "Email diperlukan";
            }
            if (!values.password) {
              errors.password = "Kata Laluan diperlukan";
            }
            if (!values.organisation) {
              errors.organisation = "Organisasi diperlukan";
            }
            if (!values.state) {
              errors.state = "Negeri diperlukan";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            const { created, data } = await createNewLeader(values);
            setSubmitting(false);
            if (created) {
              setSuccess(true);
            } else {
              setSuccess(false);
              setMsg(data.message || "something went wrong");
            }
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="col-md-12">
                <TextField
                  name="name"
                  label="Nama Penuh"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                />
                <br />
                {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}
              </div>
              <div className="col-md-12">
                <TextField
                  name="nationalId"
                  label="No Kad Pengenalan"
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nationalId}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                />
                <br />
                {errors.nationalId && (
                  <p style={{ color: "red" }}>{errors.nationalId}</p>
                )}
              </div>
              <div className="col-md-12">
                <TextField
                  name="phone"
                  label="No Telefon Bimbit"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                />
                <br />
                {errors.phone && <p style={{ color: "red" }}>{errors.phone}</p>}
              </div>
              <div className="col-md-12">
                <TextField
                  name="email"
                  label="Email"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                />
                <br />
                {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
              </div>
              <div className="col-md-12">
                <TextField
                  name="password"
                  label="Kata Laluan"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                />
                <br />
                {errors.password && (
                  <p style={{ color: "red" }}>{errors.password}</p>
                )}
              </div>
              <div className="col-md-12">
                <TextField
                  name="organisation"
                  label="Organisasi"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.organisation}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  required
                />
                <br />
                {errors.organisation && (
                  <p style={{ color: "red" }}>{errors.organisation}</p>
                )}
              </div>
              <div className="col-md-12 mb-2">
                <Autocomplete
                  options={states}
                  getOptionLabel={(option) => option}
                  onChange={(_e, val) =>
                    setFieldValue("state", val ? val : undefined)
                  }
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Negeri"
                      variant="outlined"
                      value={values.state}
                      fullWidth
                      required
                    />
                  )}
                />
                <br />
                {errors.state && <p style={{ color: "red" }}>{errors.state}</p>}
              </div>
              <div className="col-md-12">
                {!isSubmitting ? (
                  <button
                    type="submit"
                    className="seo_btn seo_btn_two btn_hover wow"
                    disabled={success}
                  >
                    Daftar
                  </button>
                ) : (
                  <CircularProgress />
                )}
              </div>
            </form>
          )}
        </Formik>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={success}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
      >
        <MuiAlert onClose={handleCloseSuccess} severity="success">
          Registration Success!
        </MuiAlert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={msg.length > 0 && !success}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
      >
        <MuiAlert onClose={handleCloseSuccess} severity="error">
          Registration Failed: {msg}!
        </MuiAlert>
      </Snackbar>
    </section>
  );
};

export default CaptainForm;
