import React from "react";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";

const Thanks = () => {
  const [isSuccess, setIsSuccess] = React.useState(true);

  React.useEffect(() => {
    const status = new URLSearchParams(window.location.search).get(
      "redirect_status"
    );
    if (status && status === "succeeded") {
      setIsSuccess(true);
    }
  }, []);

  return (
    <div className="body_wrapper">
      <section className="error_two_area">
        <div className="container flex">
          <div className="error_content_two text-center">
            {isSuccess ? (
              <>
                <img
                  width="450"
                  src={require("../img/new/high-five.png")}
                  alt=""
                />
                <h2>Thanks for your kind donation.</h2>
              </>
            ) : (
              <>
                <img
                  width="450"
                  src={require("../img/new/awkward.png")}
                  alt=""
                />
                <h2>
                  Your payment failed{" "}
                  <span role="img" aria-label="sad">
                    🥺
                  </span>
                </h2>
              </>
            )}
            <p>
              {isSuccess
                ? "High five for you and let's visit our homepage"
                : " Please try again by visiting donation page"}
            </p>
            <a
              href={isSuccess ? "/" : "/donation"}
              className="about_btn btn_hover"
            >
              {isSuccess ? "Back to Home Page" : " Back to Donation Page"}{" "}
              <i className="arrow_right"></i>
            </a>
          </div>
        </div>
      </section>
      <Footer FooterData={FooterData} />
    </div>
  );
};

export default Thanks;
