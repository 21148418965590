import React, { Component } from "react";
import { trackEvent, ButtonEventName } from "../services/analytics";

class Subscribe extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="seo_call_to_action_area sec_pad">
          <div className="container">
            <div className="seo_call_action_text">
              <h2>Ayuh bersama menjana Perubahan</h2>
              <a
                href="/register"
                className="about_btn"
                onClick={() => trackEvent(ButtonEventName.Register)}
              >
                Daftar Sekarang
              </a>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Subscribe;
