import React, { useState } from "react";
import { Formik } from "formik";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Switch
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { enrollForEvent } from "../../api/event";
import { phoneNumber } from "../../utils/number";
import { branch } from "../../utils/Constant";

const useStyles = makeStyles(theme => ({
  actions: {
    justifyContent: "center", // Align actions to the left
    paddingLeft: theme.spacing(2) // Optional padding for additional space
  }
}));

export default function EventDialog({
  id,
  fee,
  title,
  open,
  setOpen,
  setSucces,
  memberDiscount,
  customFields
}) {
  const [uri, setURI] = React.useState(null);
  const [isMember, setIsMember] = useState(true);
  const [price, setPrice] = useState(Number(fee));
  const [customValues, setCustomValues] = useState({});

  const classes = useStyles();

  React.useEffect(() => {
    if (isMember && memberDiscount) {
      setPrice(Number(fee) - (Number(memberDiscount) / 100) * Number(fee));
    } else {
      setPrice(Number(fee));
    }
  }, [isMember, memberDiscount, fee]);

  React.useEffect(() => {
    if (uri) {
      return (window.location.href = uri);
    }
  }, [uri]);

  React.useEffect(() => {
    setIsMember(true);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const renderCustomField = (key, customField) => {
    const { label, options, type } = customField;
    if (type === "Text") {
      return (
        <div className="col-12">
          <TextField
            margin="normal"
            type="text"
            variant="outlined"
            fullWidth
            onChange={e =>
              setCustomValues({
                ...customValues,
                [label]: e.target.value
              })
            }
          />
        </div>
      );
    } else if (type === "Dropdown") {
      return (
        <div className="col-12">
          <Autocomplete
            options={options.split(",")}
            getOptionLabel={options => options}
            onSelect={e => {
              setCustomValues({ ...customValues, [label]: e.target.value });
            }}
            renderInput={params => (
              <TextField
                {...params}
                label={label}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </div>
      );
    } else if (type === "Checkbox") {
      return (
        <div className="col-12">
          <FormGroup
            onChange={e =>
              setCustomValues({
                ...customValues,
                [label]: {
                  ...customValues[label],
                  [e.target.value]: e.target.checked
                }
              })
            }
          >
            {options.split(",").map(val => (
              <FormControlLabel
                key={val}
                value={val}
                control={<Checkbox />}
                label={val}
              />
            ))}
          </FormGroup>
        </div>
      );
    } else if (type === "Radio") {
      return (
        <div className="col-12">
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              onChange={e =>
                setCustomValues({ ...customValues, [label]: e.target.value })
              }
            >
              {options.split(",").map(val => (
                <FormControlLabel
                  key={val}
                  value={val}
                  control={<Radio />}
                  label={val}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      );
    } else if (type === "Switch") {
      return (
        <div className="col-12">
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  onChange={e =>
                    setCustomValues({
                      ...customValues,
                      [key]: e.target.checked
                    })
                  }
                />
              }
              label={label}
            />
          </FormGroup>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <b> {`Pendaftaran ${title}`}</b>
        </DialogTitle>
        <Formik
          initialValues={{
            nationalId: "",
            fullname: "",
            phone: "",
            email: "",
            branch: 0
          }}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            const { nationalId, fullname, phone, email, branch } = values;
            const obj = isMember
              ? { nationalId }
              : {
                  fullname,
                  nationalId,
                  phone: phoneNumber(phone),
                  email,
                  branch
                };
            return enrollForEvent(isMember, id, obj, customValues)
              .then(res => {
                setSubmitting(false);
                if (res.url) {
                  setURI(res.url);
                }
                setTimeout(() => setSucces(true), 2000);
                setOpen(false);
              })
              .catch(err => {
                setSubmitting(false);
                console.log(err);
                if (err.message === "Member already enrolled") {
                  setErrors({
                    nationalId: "Anda sudah Mendaftar untuk aktiviti ini"
                  });
                }
                if (
                  err.message ===
                  "Cannot read property 'memberStatus' of undefined"
                ) {
                  setErrors({
                    nationalId:
                      "Anda bukan ahli. Sekiranya pernah berdaftar sila layari pautan borang dibawah atau hubungi pasukan kami"
                  });
                }
                if (err.message === "Membership is not active") {
                  setErrors({
                    nationalId: "Keahlian anda tidak aktif. Sila perbaharui.."
                  });
                  setTimeout(() => (window.location.href = "/renew"), 1500);
                }
              });
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue
          }) => (
            <>
              <DialogContent>
                {isMember ? (
                  <div>
                    <DialogContentText>
                      Sila isi borang dengan lengkap
                    </DialogContentText>
                    <TextField
                      name="nationalId"
                      label="No Kad Pengenalan"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nationalId}
                    />
                    <p style={{ color: "red", marginTop: 2 }}>
                      {errors.nationalId}
                    </p>
                    {fee !== 0 && (
                      <p>{`Pembayaran sebanyak RM${price} akan dikenakan ketika pendaftaran`}</p>
                    )}
                    {!errors.remarks && (
                      <Button
                        style={{ color: "#5e2ced" }}
                        onClick={() => setIsMember(false)}
                      >
                        Bukan Ahli GEMA? Daftar sebagai peserta biasa
                      </Button>
                    )}
                    <p style={{ color: "Black" }}>{errors.id}</p>
                    <p style={{ color: "green" }}>{errors.remarks}</p>
                  </div>
                ) : (
                  <div>
                    <DialogContentText>
                      Sila masukkan semua butiran dibawah
                    </DialogContentText>
                    <div className="row">
                      <div className="col-12">
                        <TextField
                          name="fullname"
                          label="Nama Penuh"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.fullname}
                          fullWidth
                        />
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <TextField
                          name="nationalId"
                          label="No Kad Pengenalan"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.nationalId}
                          fullWidth
                        />
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <Autocomplete
                          options={branch}
                          getOptionLabel={option => option.title}
                          onChange={(_e, val) =>
                            setFieldValue("branch", val ? val.value : undefined)
                          }
                          onBlur={handleBlur}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Negeri"
                              value={values.branch}
                              fullWidth
                            />
                          )}
                        />
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <TextField
                          name="phone"
                          label="No Telefon Bimbit"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                          fullWidth
                        />
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <TextField
                          name="email"
                          label="E-mel"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          fullWidth
                        />
                      </div>
                    </div>
                    <p style={{ color: "red", marginTop: 2 }}>
                      {errors.nationalId}
                    </p>
                    {fee !== 0 && (
                      <p>{`Pembayaran sebanyak RM${price} akan dikenakan ketika pendaftaran`}</p>
                    )}
                    {!errors.remarks && (
                      <Button
                        style={{ color: "#5e2ced" }}
                        onClick={() => setIsMember(true)}
                      >
                        Ahli GEMA? Daftar dengan no kad pengenalan
                      </Button>
                    )}
                    <p style={{ color: "Black" }}>{errors.id}</p>
                    <p style={{ color: "green" }}>{errors.remarks}</p>
                  </div>
                )}

                {customFields && (
                  <>
                    <p className="mt-4">Maklumat Tambahan</p>
                    {Object.keys(customFields).map((key, index) => {
                      if (customFields[key].disabled) {
                        return null;
                      } else {
                        return (
                          <div className="row mb-4" key={key}>
                            <div className="col-12">
                              <h6>{customFields[key].label}</h6>
                            </div>
                            {renderCustomField(key, customFields[key])}
                          </div>
                        );
                      }
                    })}
                  </>
                )}
              </DialogContent>
              <DialogActions className={classes.actions}>
                <Button
                  className="btn"
                  disabled={isSubmitting}
                  onClick={handleClose}
                  color="primary"
                >
                  Batal
                </Button>
                <Button
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  color="primary"
                >
                  Daftar
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}
