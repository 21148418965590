import React from "react";
import Reveal from "react-reveal";
import Sectitle from "../components/Title/Sectitle";
import { Formik, FieldArray } from "formik";
import {
  TextField,
  FormControlLabel,
  FormLabel,
  Checkbox,
  FormControl,
  Radio,
  RadioGroup,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import qs from "query-string";
import {
  branch,
  interests,
  states,
  religions,
  races,
  genders,
  education,
} from "../utils/Constant";
import { createNewMember } from "../api/member";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
import {
  trackEvent,
  PageEventName,
  ButtonEventName,
} from "../services/analytics";

const Registration = (props) => {
  const [details, setDetails] = React.useState({
    interest: [],
    memberType: 0,
  });
  const [success, setSuccess] = React.useState(false);
  const [failure, setFailure] = React.useState(false);
  const [error, setError] = React.useState("Registration Failed!");

  const [uri, setURI] = React.useState("");

  React.useEffect(() => {
    trackEvent(PageEventName.Registration);
    const query = qs.parse(window.location.search);
    if (query && query.membership) {
      setDetails({ interest: [], memberType: Number(query.membership) });
    }
  }, []);

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccess(false);
  };
  const handleCloseFailure = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setFailure(false);
  };

  if (success) {
    return (window.location.href = uri);
  }

  return (
    <React.Fragment>
      <section id="home" className="app_banner_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <Reveal bottom cascade>
                <div className="app_banner_contentmt mt_40">
                  <h1
                    className="f_p f_700 f_size_40 w_color mb_20 wow fadeInLeft"
                    data-wow-delay="0.2s"
                  >
                    Permohonan Keahlian Pemuda GEMA
                  </h1>
                  <p
                    className="f_400 f_size_18 l_height30 w_color wow fadeInLeft"
                    data-wow-delay="0.3s"
                  >
                    Terima kasih kerana memilih untuk menyertai kami.
                  </p>
                  <div className="action_btn d-flex align-items-center mt_60">
                    <a
                      href="#form"
                      className="btn_hover app_btn wow fadeInLeft"
                      data-wow-delay="0.5s"
                    >
                      Mulakan
                    </a>
                    <a
                      href="/"
                      className="agency_banner_btn_two wow fadeInLeft m-3"
                      style={{ color: "white" }}
                    >
                      kembali <i className="ti-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </Reveal>
            </div>
            <div className="col-lg-5">
              <div className="app_img">
                <img
                  className="app_screen one wow fadeInDown"
                  src={require("../img/home7/01.png")}
                  alt=""
                />
                <img
                  className="app_screen two wow fadeInDown"
                  src={require("../img/home7/03.png")}
                  alt=""
                />
                <img
                  className="app_screen three wow fadeInDown"
                  src={require("../img/home7/02.png")}
                  alt=""
                />
                <img
                  className="mobile"
                  src={require("../img/home7/app.png")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="form" className="bg_color">
        <Sectitle
          Title="Maklumat Pemohon"
          TitleP="Ruangan dengan tanda * wajib diisi."
          tClass="t_color3"
          sClass="sec_title text-center mb_70"
        />
        <div className="container custom_container">
          <div className="row">
            <div className="col-md-12">
              <Paper
                style={{
                  backgroundColor: `rgba(255,255,255,0.7)`,
                }}
              >
                <Formik
                  style={{ maxWidth: 400 }}
                  initialValues={{
                    ...details,
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    trackEvent(ButtonEventName.RegisterSubmission);
                    createNewMember(JSON.stringify(values, null, 2))
                      .then((url) => {
                        setURI(url);
                        setSuccess(true);
                        setSubmitting(false);
                      })
                      .catch((err) => {
                        setError(err.message || "Failed");
                        setFailure(true);
                        setSubmitting(false);
                      });
                  }}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="row p-3">
                        <div className="col-md-12 col-sm-12">
                          <h5>Bahagian 1: Maklumat Peribadi</h5>
                        </div>
                        <div className="col-md-12 col-sm-12">
                          <TextField
                            label="Nama Penuh"
                            margin="normal"
                            type="text"
                            name="fullname"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.fullname}
                            variant="outlined"
                            fullWidth
                            required
                          />
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <TextField
                            label="No. Kad Pengenalan"
                            margin="normal"
                            type="number"
                            name="nationalId"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.nationalId}
                            variant="outlined"
                            fullWidth
                            required
                          />
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <TextField
                            label="Umur"
                            margin="normal"
                            fullWidth
                            type="number"
                            name="age"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.age}
                            variant="outlined"
                            required
                          />
                          {values.age > 35 && (
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              Harap Maaf! Had umur untuk keahlian adalah 35
                              Tahun
                            </span>
                          )}
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <Autocomplete
                            options={races}
                            getOptionLabel={(option) => option}
                            onChange={(_e, val) => {
                              setFieldValue("race", val);
                            }}
                            onBlur={handleBlur}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Bangsa"
                                margin="normal"
                                variant="outlined"
                                value={values.race}
                                required
                                fullWidth
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <Autocomplete
                            options={religions}
                            getOptionLabel={(option) => option}
                            onChange={(_e, val) => {
                              setFieldValue("religion", val);
                            }}
                            onBlur={handleBlur}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Agama"
                                margin="normal"
                                variant="outlined"
                                value={values.religion}
                                required
                                fullWidth
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <Autocomplete
                            options={genders}
                            getOptionLabel={(option) => option}
                            name="gender"
                            onChange={(_e, val) => {
                              setFieldValue("gender", val);
                            }}
                            onBlur={handleBlur}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Jantina"
                                margin="normal"
                                variant="outlined"
                                value={values.gender}
                                required
                                fullWidth
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <Autocomplete
                            options={education}
                            getOptionLabel={(option) => option}
                            onChange={(_e, val) => {
                              setFieldValue("education", val);
                            }}
                            onBlur={handleBlur}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Taraf Pendidikan"
                                margin="normal"
                                variant="outlined"
                                value={values.education}
                                required
                                fullWidth
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <TextField
                            label="Pekerjaan"
                            margin="normal"
                            fullWidth
                            type="text"
                            name="occupation"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.occupation}
                            variant="outlined"
                            required
                          />
                        </div>
                        <div className="col-md-12 col-sm-12">
                          <TextField
                            label="Alamat majikan"
                            margin="normal"
                            fullWidth
                            type="text"
                            name="employerAddress"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.employerAddress}
                            variant="outlined"
                            required
                          />
                        </div>
                        <div className="col-md-12 col-sm-12">
                          <h5 className="mt-3">
                            Bahagian 2: Maklumat Perhubungan
                          </h5>
                        </div>

                        <div className="col-md-4 col-sm-12">
                          <TextField
                            label="Email"
                            margin="normal"
                            fullWidth
                            type="text"
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                            variant="outlined"
                            required
                          />
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <TextField
                            label="No HP"
                            margin="normal"
                            fullWidth
                            type="number"
                            name="phone"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.phone}
                            variant="outlined"
                            required
                          />
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <TextField
                            label="Profil Facebook"
                            margin="normal"
                            fullWidth
                            type="text"
                            name="fbProfile"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.fbProfile}
                            variant="outlined"
                          />
                        </div>
                        <div className="col-md-12 col-sm-12">
                          <TextField
                            label="Alamat surat menyurat"
                            margin="normal"
                            fullWidth
                            type="text"
                            name="address"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.address}
                            variant="outlined"
                            required
                          />
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <TextField
                            label="Bandar"
                            margin="normal"
                            fullWidth
                            type="text"
                            name="city"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.city}
                            variant="outlined"
                            required
                          />
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <Autocomplete
                            options={states}
                            getOptionLabel={(option) => option}
                            onChange={(_e, val) => setFieldValue("state", val)}
                            onBlur={handleBlur}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Negeri"
                                margin="normal"
                                variant="outlined"
                                value={values.state}
                                fullWidth
                                required
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <TextField
                            label="Poskod"
                            margin="normal"
                            fullWidth
                            type="text"
                            name="postcode"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.postcode}
                            variant="outlined"
                            required
                          />
                        </div>
                        <div className="col-md-12 col-sm-12">
                          <h5 className="mt-3">
                            Bahagian 3: Maklumat Keahlian
                          </h5>
                        </div>
                        <div className="col-md-12 col-sm-12">
                          <Autocomplete
                            options={branch}
                            getOptionLabel={(option) => option.title}
                            onChange={(_e, val) =>
                              setFieldValue(
                                "branch",
                                val ? val.value : undefined
                              )
                            }
                            onBlur={handleBlur}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Cawangan Gema"
                                margin="normal"
                                variant="outlined"
                                value={values.branch}
                                required
                                fullWidth
                              />
                            )}
                          />
                        </div>
                        <div className="col-md-12 col-sm-12">
                          <p className="mt-3">
                            Siapakah yang perkenalkan GEMA kepada anda?
                            (*sekiranya ada):
                          </p>
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <TextField
                            label="Nama rakan Ahli Gema"
                            margin="normal"
                            fullWidth
                            type="text"
                            name="introcuderName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.introcuderName}
                            variant="outlined"
                          />
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <TextField
                            label="No Telefon rakan Ahli Gema"
                            margin="normal"
                            fullWidth
                            type="number"
                            name="introducerContact"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.introducerContact}
                            variant="outlined"
                          />
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <FormLabel className="mt-3" component="legend">
                            Sila pilih minat anda *
                          </FormLabel>
                          <FieldArray
                            name="interest"
                            render={(arrayHelpers) => (
                              <div>
                                {interests.map((tag) => (
                                  <div key={tag}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="int"
                                          value={tag}
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              arrayHelpers.push(tag);
                                            } else {
                                              const idx = values.interest.indexOf(
                                                tag
                                              );
                                              arrayHelpers.remove(idx);
                                            }
                                          }}
                                        />
                                      }
                                      label={tag}
                                    />
                                    <br />
                                  </div>
                                ))}
                              </div>
                            )}
                          />
                        </div>
                        <div className="col-md-6 col-sm-12">
                          <FormLabel className="mt-3" component="legend">
                            Jenis Keahlian
                          </FormLabel>
                          <p>
                            Yuran Asas: RM10 perlu dibayar secara tahunan.{" "}
                            <br /> Yuran Penuh: RM55 dibayar sekali sehingga
                            tamat keahlian.
                          </p>
                          <p>Saya berminat untuk menjadi ahli:</p>
                          <FormControl>
                            <RadioGroup
                              aria-label="gender"
                              name="memberType"
                              value={values.memberType}
                            >
                              <FormControlLabel
                                value={0}
                                control={<Radio color="primary" />}
                                label="Yuran Asas"
                                onChange={() => setFieldValue("memberType", 0)}
                              />
                              <FormControlLabel
                                value={1}
                                control={<Radio color="primary" />}
                                label="Yuran Penuh"
                                onChange={() => setFieldValue("memberType", 1)}
                              />
                            </RadioGroup>
                          </FormControl>
                          <lottie-player
                            src="https://assets8.lottiefiles.com/datafiles/6WfDdm3ooQTEs1L/data.json"
                            background="transparent"
                            speed="3"
                            style={{
                              width: 300,
                              height: 300,
                            }}
                            loop
                            autoplay
                          />
                        </div>
                        <div className="col-md-12 col-sm-12 text-center">
                          {!isSubmitting ? (
                            <>
                              <button
                                type="submit"
                                variant="contained"
                                className="seo_btn seo_btn_one btn_hover wow"
                              >
                                Hantar Permohonan
                              </button>
                              <p className="mt-3">
                                Dengan menghantar permohonan, anda telah
                                bersetuju dengan segala syarat seperti yang
                                termaktub dalam
                                <br /> perlembagaan Pertubuhan Pemuda GEMA
                                Malaysia
                              </p>
                            </>
                          ) : (
                            <CircularProgress />
                          )}
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </Paper>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Footer FooterData={FooterData} />
      </section>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={success}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
      >
        <MuiAlert onClose={handleCloseSuccess} severity="success">
          Registration Success!
        </MuiAlert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={failure}
        autoHideDuration={6000}
        onClose={handleCloseFailure}
      >
        <MuiAlert onClose={handleCloseFailure} severity="error">
          {error}
        </MuiAlert>
      </Snackbar>
    </React.Fragment>
  );
};

export default Registration;
