import React, { useEffect } from "react";
import { Tab, Tabs } from "@material-ui/core";
import qs from "query-string";
import Footer from "../components/Footer/Footer";
import ChatClients from "../components/ChatClients";
import FooterData from "../components/Footer/FooterData";
import VolunteerForm from "../components/Msf/VolunteerForm";
import LeaderForm from "../components/Msf/LeaderForm";
import CaptainForm from "../components/Msf/CaptainForm";
import { TabPanel } from "../components/TabPanel";

const MsfRegistration = () => {
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    const query = qs.parse(window.location.search);
    if (query.role) {
      setValue(Number(query.role));
    }
  }, []);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="body_wrapper">
      <div className="event_img align-items-right">
        <img
          width="100%"
          src={require("../img/attend/mysavefood2.jpeg")}
          alt=""
        />
      </div>
      <Tabs value={value} onChange={handleTabChange} centered>
        <Tab label="Pendaftaran Sukarelawan" />
        <Tab label="Pendaftaran Ketua Bazar" />
        <Tab label="Pendaftaran Ketua Negeri" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <VolunteerForm />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <LeaderForm />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CaptainForm />
      </TabPanel>
      <div className="text-center">
        <button
          onClick={() => (window.location.href = "/report/msf")}
          type="submit"
          className="btn_three sign_btn_transparent"
        >
          Akses Portal Ketua Bazar MSF 2024
        </button>
      </div>
      <ChatClients />
      <Footer FooterData={FooterData} />
    </div>
  );
};
export default MsfRegistration;
