import React, { Component } from "react";
import ErpIconitem from "./ErpIconitem";
import Slider from "react-slick";

class ErpFeaturesitem extends Component {
  render() {
    let { rowClass } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      speed: 2000,
      arrows: false,
      fade: true,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className={`row erp_item_features ${rowClass}`}>
        <div className="col-lg-6">
          <div className="erp_features_img_two">
            <Slider className="app_screenshot_slider" {...settings}>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/muzik-gema/mg1.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/muzik-gema/mg8.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/muzik-gema/mg2.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/muzik-gema/mg4.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/muzik-gema/mg7.png")}
                    alt=""
                  />
                </div>
              </div>
            </Slider>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="erp_content_two">
            <div className="hosting_title erp_title">
              <h2>MUZIK GEMA</h2>
              <p>
                Siapa yang tidak sukakan muzik? <br />
                Muzik GEMA merupakan satu platform bagi para belia yang cintakan
                muzik untuk berkarya dan menyerlahkan bakat mereka melalui
                medium yang positif. Pemuda GEMA percaya muzik yang disampaikan
                oleh pasukan Muzik GEMA mampu membawa mesej positif dan
                menyatupadukan masyarakat.
              </p>
            </div>
            <ErpIconitem 
              iconName="icon_ribbon_alt" 
              erpText="Spektra Muzika" />
            <ErpIconitem
              iconName="icon_ribbon_alt"
              erpText="Cipta Lagu Anak Muda"
            />
          </div>
        </div>
      </div>
    );
  }
}
export default ErpFeaturesitem;
