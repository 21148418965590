import React, { Component } from "react";

class ScheduleTab extends Component {
  render() {
    return (
      <section className="event_schedule_area sec_pad">
        <div className="container">
          <div className="hosting_title security_title text-center">
            <h2>
              ATURCARA MAJLIS
            </h2>
          </div>
          <div className="event_schedule_inner">
            <div className="tab-content event_tab_content">
              <div
                className="tab-pane fade show active"
              >
                <div className="media">
                  <div className="media-left">
                    <img
                      src={require("../img/home-event/conference.jpg")}
                      alt=""
                    />
                    <a href="/#">
                      <i className="icon_clock_alt"></i>10:30 - 12:30 AM
                    </a>
                    <a href="/#">
                      <i className="icon_pin_alt"></i>Room 2
                    </a>
                  </div>
                  <div className="media-body">
                    <h3 className="h_head">The future of the UI/UX</h3>
                    <span>
                      by <a href="/#">Hanson Deck</a>
                    </span>
                    <p>
                      Lost the plot lurgy on your bike mate Oxford hanky panky
                      blow off haggle golly gosh do one, is tosser car boot
                      knees.
                    </p>
                  </div>
                </div>
                <div className="media">
                  <div className="media-left">
                    <img
                      src={require("../img/home-event/digital.jpg")}
                      alt=""
                    />
                    <a href="/#">
                      <i className="icon_clock_alt"></i>10:30 - 12:30 AM
                    </a>
                    <a href="/#">
                      <i className="icon_pin_alt"></i>Room 2
                    </a>
                  </div>
                  <div className="media-body">
                    <h3 className="h_head">Opening Conference</h3>
                    <span>
                      by <a href="/#">Hanson Deck</a>
                    </span>
                    <p>
                      Lost the plot lurgy on your bike mate Oxford hanky panky
                      blow off haggle golly gosh do one, is tosser car boot
                      knees.
                    </p>
                  </div>
                </div>
                <div className="media">
                  <div className="media-left">
                    <img
                      src={require("../img/home-event/marketing.jpg")}
                      alt=""
                    />
                    <a href="/#">
                      <i className="icon_clock_alt"></i>10:30 - 12:30 AM
                    </a>
                    <a href="/#">
                      <i className="icon_pin_alt"></i>Room 2
                    </a>
                  </div>
                  <div className="media-body">
                    <h3 className="h_head">Digital World Event Information</h3>
                    <span>
                      by <a href="/#">Hanson Deck</a>
                    </span>
                    <p>
                      Lost the plot lurgy on your bike mate Oxford hanky panky
                      blow off haggle golly gosh do one, is tosser car boot
                      knees.
                    </p>
                  </div>
                </div>
                <div className="media">
                  <div className="media-left">
                    <img
                      src={require("../img/home-event/conference.jpg")}
                      alt=""
                    />
                    <a href="/#">
                      <i className="icon_clock_alt"></i>10:30 - 12:30 AM
                    </a>
                    <a href="/#">
                      <i className="icon_pin_alt"></i>Room 2
                    </a>
                  </div>
                  <div className="media-body">
                    <h3 className="h_head">Digital Marketing Theory</h3>
                    <span>
                      by <a href="/#">Hanson Deck</a>
                    </span>
                    <p>
                      Lost the plot lurgy on your bike mate Oxford hanky panky
                      blow off haggle golly gosh do one, is tosser car boot
                      knees.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ScheduleTab;
