export const branch = [
  { title: "Perlis", value: 0 },
  { title: "Kedah", value: 1 },
  { title: "Pulau Pinang", value: 2 },
  { title: "Perak", value: 3 },
  { title: "Selangor", value: 4 },
  { title: "Kuala Lumpur", value: 5 },
  { title: "Negeri Sembilan", value: 6 },
  { title: "Melaka", value: 7 },
  { title: "Johor", value: 8 },
  { title: "Pahang", value: 9 },
  { title: "Terengganu", value: 10 },
  { title: "Kelantan", value: 11 },
  { title: "Sabah", value: 12 },
  { title: "Sarawak", value: 13 }
];

export const states = [
  "Perlis",
  "Kedah",
  "Pulau Pinang",
  "Perak",
  "Selangor",
  "Kuala Lumpur",
  "Negeri Sembilan",
  "Melaka",
  "Johor",
  "Pahang",
  "Terengganu",
  "Kelantan",
  "Sabah",
  "Sarawak",
  "Labuan",
  "Putrajaya"
];

export const interests = [
  "GEMA He​roes",
  "GEMA Gr​een Heroes",
  "GEMA Negative Heroes",
  "GEMA Horizon",
  "GEMA Muzik",
  "GEMA E-Sport",
  "GEMA Fit",
  "GEMA Future"
];

export const genders = ["LELAKI", "PEREMPUAN"];

export const races = [
  "MELAYU",
  "CINA",
  "INDIA",
  "IBAN",
  "BUGIS",
  "KADAZANDUSUN",
  "BAJAU",
  "MURUT",
  "LAIN-LAIN"
];

export const religions = [
  "ISLAM",
  "BUDDHA",
  "KRISTIAN",
  "HINDU",
  "SIKH",
  "LAIN-LAIN"
];

export const education = [
  "SPM",
  "STPM",
  "DIPLOMA",
  "SARJANA MUDA",
  "SARJANA",
  "DOKTOR FALSAFAH",
  "LAIN-LAIN"
];

export const weathers = [
  { title: "Cerah", value: "Cerah" },
  { title: "Mendung", value: "Mendung" },
  { title: "Hujan Renyai", value: "Hujan Renyai" },
  { title: "Hujan Lebat", value: "Hujan Lebat" },
  { title: "Hujan Sekejap", value: "Hujan Sekejap" }
];

export const weathersWithColors = [
  { name: "Cerah", color: "rgba(255, 233, 99, 1)" },
  { name: "Mendung", color: "rgba(255, 109, 109, 1)" },
  { name: "Hujan Renyai", color: "rgba(138, 208, 206, 1)" },
  { name: "Hujan Lebat", color: "rgba(189, 247, 255, 1)" },
  { name: "Hujan Sekejap", color: "rgba(75, 89, 94, 1)" }
];

export const bazarList = [
  //Perlis
  {
    title: "Bazar Ramadan Arau",
    value: "Bazar Ramadan Arau",
    state: "Perlis"
  },
  {
    title: "Bazar Ramadan Persiaran Wawasan, Kangar",
    value: "Bazar Ramadan Persiaran Wawasan, Kangar",
    state: "Perlis"
  },
  //Kedah
  {
    title: "Bazar Ramadan Tapak Penjaja, Changlun",
    value: "Bazar Ramadan Tapak Penjaja, Changlun",
    state: "Kedah"
  },
  {
    title: "Bazar Ramadan Taman Mahsuri, Padang Serai",
    value: "Bazar Ramadan Taman Mahsuri, Padang Serai",
    state: "Kedah"
  },
  {
    title: "Bazar Ramadan Sungai Korok, Kepala Batas",
    value: "Bazar Ramadan Sungai Korok, Kepala Batas",
    state: "Kedah"
  },
  {
    title: "Bazar Ramadan Taman Seri Aman, Jitra",
    value: "Bazar Ramadan Taman Seri Aman, Jitra",
    state: "Kedah"
  },
  {
    title: "Bazar Ramadan Kepala Batas",
    value: "Bazar Ramadan Kepala Batas",
    state: "Kedah"
  },
  {
    title: "Bazar Ramadan Pekan, Sik",
    value: "Bazar Ramadan Pekan, Sik",
    state: "Kedah"
  },
  {
    title: "Bazar Ramadan Tanah Merah, Pendang",
    value: "Bazar Ramadan Tanah Merah, Pendang",
    state: "Kedah"
  },
  {
    title: "Bazar Ramadan Pekan, Pendang",
    value: "Bazar Ramadan Pekan, Pendang",
    state: "Kedah"
  },
  {
    title: "Bazar Ramadan Stadium, Alor Setar",
    value: "Bazar Ramadan Stadium, Alor Setar",
    state: "Kedah"
  },
  {
    title: "Bazar Ramadan Taman Wira, Alor Setar",
    value: "Bazar Ramadan Taman Wira, Alor Setar",
    state: "Kedah"
  },
  {
    title: "Bazar Ramadan Klang Lama, kulim",
    value: "Bazar Ramadan Klang Lama, kulim",
    state: "Kedah"
  },
  {
    title: "Bazar Ramadan Bandar Puteri Jaya, SP",
    value: "Bazar Ramadan Bandar Puteri Jaya, SP",
    state: "Kedah"
  },
  {
    title: "Bazar Ramadan Kuala Ketil, Baling",
    value: "Bazar Ramadan Kuala Ketil, Baling",
    state: "Kedah"
  },
  {
    title: "Bazar Ramadan Taman Ria, SP",
    value: "Bazar Ramadan Taman Ria, SP",
    state: "Kedah"
  },
  {
    title: "Bazar Ramadan Pekan Serdang",
    value: "Bazar Ramadan Pekan Serdang",
    state: "Kedah"
  },
  {
    title: "Bazar Ramadan Taman Aman",
    value: "Bazar Ramadan Taman Aman",
    state: "Kedah"
  },
  {
    title: "Bazar Ramadan Pokok Sena",
    value: "Bazar Ramadan Pokok Sena",
    state: "Kedah"
  },
  {
    title: "Bazar Ramadan Kodiang",
    value: "Bazar Ramadan Kodiang",
    state: "Kedah"
  },
  //Kelantan
  {
    title: "Bazar Ramadan UMK, Pengkalan Chepa",
    value: "Bazar Ramadan UMK, Pengkalan Chepa",
    state: "Kelantan"
  },
  {
    title: "Bazar Ramadan Stadium, Kota Bharu",
    value: "Bazar Ramadan Stadium, Kota Bharu",
    state: "Kelantan"
  },
  {
    title: "Bazar Ramadan Bazar Kubang Kerian, Kota Bharu",
    value: "Bazar Ramadan Bazar Kubang Kerian, Kota Bharu",
    state: "Kelantan"
  },
  {
    title: "Bazar Ramadan Pantai Irama, Bachok",
    value: "Bazar Ramadan Pantai Irama, Bachok",
    state: "Kelantan"
  },
  {
    title: "Bazar Ramadan Mydin Tunjong, Kota Bharu",
    value: "Bazar Ramadan Mydin Tunjong, Kota Bharu",
    state: "Kelantan"
  },
  //Perak
  {
    title: "Bazar Ramadan Medan Gopeng, Ipoh",
    value: "Bazar Ramadan Medan Gopeng, Ipoh",
    state: "Perak"
  },
  {
    title: "Bazar Ramadan Stadium Indra Mulia, Ipoh",
    value: "Bazar Ramadan Stadium Indra Mulia, Ipoh",
    state: "Perak"
  },
  {
    title: "Bazar Ramadan Taman Cempaka, Ipoh",
    value: "Bazar Ramadan Taman Cempaka, Ipoh",
    state: "Perak"
  },
  {
    title: "Bazar Ramadan Dataran Teluk Intan",
    value: "Bazar Ramadan Dataran Teluk Intan",
    state: "Perak"
  },
  {
    title: "Bazar Ramadan Bandar Universiti, Seri Iskandar",
    value: "Bazar Ramadan Bandar Universiti, Seri Iskandar",
    state: "Perak"
  },
  {
    title: "Bazar Ramadan Proton City, Tanjung Malim",
    value: "Bazar Ramadan Proton City, Tanjung Malim",
    state: "Perak"
  },
  {
    title: "Bazar Ramadan Pekan Tanjung Malim",
    value: "Bazar Ramadan Pekan Tanjung Malim",
    state: "Perak"
  },
  {
    title: "Bazar Ramadan Pekan Teluk Intan",
    value: "Bazar Ramadan Pekan Teluk Intan",
    state: "Perak"
  },
  {
    title: "Bazar Ramadan Kg. Bahagia, Teluk Intan",
    value: "Bazar Ramadan Kg. Bahagia, Teluk Intan",
    state: "Perak"
  },
  {
    title: "Bazar Ramadan Lenggong",
    value: "Bazar Ramadan Lenggong",
    state: "Perak"
  },
  {
    title: "Bazar Ramadan Taman Perpaduan",
    value: "Bazar Ramadan Taman Perpaduan",
    state: "Perak"
  },
  {
    title: "Bazar Ramadan Silibin Manjoi",
    value: "Bazar Ramadan Silibin Manjoi",
    state: "Perak"
  },
  {
    title: "Bazar Ramadan Selekoh, Bagan Datuk",
    value: "Bazar Ramadan Selekoh, Bagan Datuk",
    state: "Perak"
  },
  {
    title: "Bazar Ramadan Sidex",
    value: "Bazar Ramadan Sidex",
    state: "Perak"
  },
  {
    title: "Bazar Ramadan Behrang",
    value: "Bazar Ramadan Behrang",
    state: "Perak"
  },
  //Pulau Pinang
  {
    title: "Bazar Ramadan Rahmah Bayan Baru",
    value: "Bazar Ramadan Rahmah Bayan Baru",
    state: "Pulau Pinang"
  },
  {
    title: "Bazar Ramadan Taman Tun Sardon",
    value: "Bazar Ramadan Taman Tun Sardon",
    state: "Pulau Pinang"
  },
  {
    title: "Bazar Ramadan Tapak Pesta",
    value: "Bazar Ramadan Tapak Pesta",
    state: "Pulau Pinang"
  },
  {
    title: "Bazar Ramadan Balik Pulau",
    value: "Bazar Ramadan Balik Pulau",
    state: "Pulau Pinang"
  },
  //Selangor
  {
    title: "Bazar Ramadan Seksyen 13 Stadium, Shah Alam",
    value: "Bazar Ramadan Seksyen 13 Stadium, Shah Alam",
    state: "Selangor"
  },
  {
    title: "Bazar Ramadan Seksyen 17, Shah Alam",
    value: "Bazar Ramadan Seksyen 17, Shah Alam",
    state: "Selangor"
  },
  {
    title: "Bazar Ramadan Seksyen 19, Shah Alam",
    value: "Bazar Ramadan Seksyen 19, Shah Alam",
    state: "Selangor"
  },
  {
    title: "Bazar Ramadan Seksyen U5",
    value: "Bazar Ramadan Seksyen U5",
    state: "Selangor"
  },
  {
    title: "Bazar Ramadan Kosmopleks, Salak Tinggi",
    value: "Bazar Ramadan Kosmopleks, Salak Tinggi",
    state: "Selangor"
  },
  {
    title: "Bazar Ramadan Bazar Kampung Seri Aman, Puchong",
    value: "Bazar Ramadan Bazar Kampung Seri Aman, Puchong",
    state: "Selangor"
  },
  {
    title: "Bazar Ramadan Puchong 2",
    value: "Bazar Ramadan Puchong 2",
    state: "Selangor"
  },
  {
    title: "Bazar Ramadan Sg Besar",
    value: "Bazar Ramadan Sg Besar",
    state: "Selangor"
  },
  {
    title: "Bazar Ramadan Sungai Tangkas",
    value: "Bazar Ramadan Sungai Tangkas",
    state: "Selangor"
  },
  {
    title: "Bazar Ramadan Prima Saujana, Kajang",
    value: "Bazar Ramadan Prima Saujana, Kajang",
    state: "Selangor"
  },
  {
    title: "Bazar Ramadan Laman Niaga Bangi",
    value: "Bazar Ramadan Laman Niaga Bangi",
    state: "Selangor"
  },
  {
    title: "Bazar Ramadan Seksyen 3",
    value: "Bazar Ramadan Seksyen 3",
    state: "Selangor"
  },
  {
    title: "Bazar Ramadan Teras Jernang",
    value: "Bazar Ramadan Teras Jernang",
    state: "Selangor"
  },
  {
    title: "Bazar Ramadan Reko Sentral",
    value: "Bazar Ramadan Reko Sentral",
    state: "Selangor"
  },
  {
    title: "Bazar Ramadan Bandar Seri Putra",
    value: "Bazar Ramadan Bandar Seri Putra",
    state: "Selangor"
  },
  {
    title: "Bazar Ramadan Bukit Mahkota",
    value: "Bazar Ramadan Bukit Mahkota",
    state: "Selangor"
  },
  {
    title: "Bazar Ramadan Taman Samudera",
    value: "Bazar Ramadan Taman Samudera",
    state: "Selangor"
  },
  {
    title: "Bazar Ramadan Taman Sri Nanding, Hulu Langat",
    value: "Bazar Ramadan Taman Sri Nanding, Hulu Langat",
    state: "Selangor"
  },
  {
    title: "Bazar Ramadan Taman Perwira, Teluk Panglima Garang",
    value: "Bazar Ramadan Taman Perwira, Teluk Panglima Garang",
    state: "Selangor"
  },
  {
    title: "Bazar Ramadan Taman Sri Andalas, Klang",
    value: "Bazar Ramadan Taman Sri Andalas, Klang",
    state: "Selangor"
  },
  {
    title: "Bazar Ramadan Bandar Baru Ampang",
    value: "Bazar Ramadan Bandar Baru Ampang",
    state: "Selangor"
  },
  {
    title: "Bazar Ramadan KMR Oneputra Residence",
    value: "Bazar Ramadan KMR Oneputra Residence",
    state: "Selangor"
  },
  //WPKL
  {
    title: "Bazar Ramadan Kampung Baru, KL",
    value: "Bazar Ramadan Kampung Baru, KL",
    state: "Kuala Lumpur"
  },
  {
    title: "Bazar Ramadan LRT Damai, KL",
    value: "Bazar Ramadan LRT Damai, KL",
    state: "Kuala Lumpur"
  },
  {
    title: "Bazar Wangsa Maju,KL",
    value: "Bazar Wangsa Maju,KL",
    state: "Kuala Lumpur"
  },
  {
    title: "Bazar Ramadan Sri Rampai (Tasik), KL",
    value: "Bazar Ramadan Sri Rampai (Tasik), KL",
    state: "Kuala Lumpur"
  },
  {
    title: "Bazar Ramadan Sri Rampai (Vanggey), KL",
    value: "Bazar Ramadan Sri Rampai (Vanggey), KL",
    state: "Kuala Lumpur"
  },
  {
    title: "Bazar Ramadan Ria, Pantai Dalam, KL",
    value: "Bazar Ramadan Ria, Pantai Dalam, KL",
    state: "Kuala Lumpur"
  },
  {
    title: "Bazar Ramadan Pandan Indah, KL",
    value: "Bazar Ramadan Pandan Indah, KL",
    state: "Kuala Lumpur"
  },
  //WPP
  {
    title: "Bazar Ramadan Presint 3, Putrajaya",
    value: "Bazar Ramadan Presint 3, Putrajaya",
    state: "Putrajaya"
  },
  {
    title: "Bazar Ramadan Jalan Tar, KL",
    value: "Bazar Ramadan Jalan Tar, KL",
    state: "Kuala Lumpur"
  },
  {
    title: "Bazar Ramadan Tasik Permaisuri, KL",
    value: "Bazar Ramadan Tasik Permaisuri, KL",
    state: "Kuala Lumpur"
  },
  //Terengganu
  {
    title: "Bazar Ramadan Gong Badak, Kuala Terengganu",
    value: "Bazar Ramadan Gong Badak, Kuala Terengganu",
    state: "Terengganu"
  },
  {
    title: "Bazar Ramadan Wakaf Tengah, Kuala Terengganu",
    value: "Bazar Ramadan Wakaf Tengah, Kuala Terengganu",
    state: "Terengganu"
  },
  {
    title: "Bazar Ramadan Jertih, Besut",
    value: "Bazar Ramadan Jertih, Besut",
    state: "Terengganu"
  },
  {
    title: "Bazar Ramadan Teluk Ketapang",
    value: "Bazar Ramadan Teluk Ketapang",
    state: "Terengganu"
  },
  {
    title: "Bazar Ramadan Pasar Marang",
    value: "Bazar Ramadan Pasar Marang",
    state: "Terengganu"
  },
  {
    title: "Bazar Ramadan Sungai Tarom",
    value: "Bazar Ramadan Sungai Tarom",
    state: "Terengganu"
  },
  //Pahang
  {
    title: "Bazar Ramadan Padang MPK, Kuantan",
    value: "Bazar Ramadan Padang MPK, Kuantan",
    state: "Pahang"
  },
  {
    title: "Bazar Ramadan TMG Mall Tanjung Lumpur",
    value: "Bazar Ramadan TMG Mall Tanjung Lumpur",
    state: "Pahang"
  },
  {
    title: "Bazar Ramadan Indera Mahkota 3, Kuantan",
    value: "Bazar Ramadan Indera Mahkota 3, Kuantan",
    state: "Pahang"
  },
  //Negeri Sembilan
  {
    title: "Bazar Ramadan Masjid Putra, Nilai",
    value: "Bazar Ramadan Masjid Putra, Nilai",
    state: "Negeri Sembilan"
  },
  {
    title: "Bazar Ramadan Taman Semarak, Nilai",
    value: "Bazar Ramadan Taman Semarak, Nilai",
    state: "Negeri Sembilan"
  },
  {
    title: "Bazar Ramadan Lotus, Bandar Baru, Nilai",
    value: "Bazar Ramadan Lotus, Bandar Baru, Nilai",
    state: "Negeri Sembilan"
  },
  {
    title: "Bazar Ramadan McDonalds, Bandar Baru, Nilai",
    value: "Bazar Ramadan McDonalds, Bandar Baru, Nilai",
    state: "Negeri Sembilan"
  },
  {
    title: "Bazar Ramadan Stadium Paroi, Seremban",
    value: "Bazar Ramadan Stadium Paroi, Seremban",
    state: "Negeri Sembilan"
  },
  {
    title: "Bazar Ramadan Bandar Seri Sendayan",
    value: "Bazar Ramadan Bandar Seri Sendayan",
    state: "Negeri Sembilan"
  },
  {
    title: "Bazar Ramadan Kuarters KLIA",
    value: "Bazar Ramadan Kuarters KLIA",
    state: "Negeri Sembilan"
  },
  //Melaka
  {
    title: "Bazar Ramadan Bandar, Jasin",
    value: "Bazar Ramadan Bandar, Jasin",
    state: "Melaka"
  },
  {
    title: "Bazar Ramadan Peringgit, Melaka",
    value: "Bazar Ramadan Peringgit, Melaka",
    state: "Melaka"
  },
  {
    title: "Bazar Ramadan Pekan Alor Gajah",
    value: "Bazar Ramadan Pekan Alor Gajah",
    state: "Melaka"
  },
  {
    title: "Bazar Ramadan Merlimau Point",
    value: "Bazar Ramadan Merlimau Point",
    state: "Melaka"
  },
  {
    title: "Bazar Ramadan Pekan Durian Tunggal",
    value: "Bazar Ramadan Pekan Durian Tunggal",
    state: "Melaka"
  },
  //Johor
  {
    title: "Bazar Ramadan Indahpura, Kulai",
    value: "Bazar Ramadan Indahpura, Kulai",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Perling Mall, Perling",
    value: "Bazar Ramadan Perling Mall, Perling",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Bukit Gambir, Tangkak",
    value: "Bazar Ramadan Bukit Gambir, Tangkak",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Sks Point, Pontian",
    value: "Bazar Ramadan Sks Point, Pontian",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Kipmall Tampoi",
    value: "Bazar Ramadan Kipmall Tampoi",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Larkin Sentral",
    value: "Bazar Ramadan Larkin Sentral",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Bazar @Mart Kempas",
    value: "Bazar Ramadan Bazar @Mart Kempas",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Taman Dahlia",
    value: "Bazar Ramadan Taman Dahlia",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Ulu Tiram",
    value: "Bazar Ramadan Ulu Tiram",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Bandar Baru Uda",
    value: "Bazar Ramadan Bandar Baru Uda",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Kampung Melayu",
    value: "Bazar Ramadan Kampung Melayu",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan KDSM Jalan Kemboja",
    value: "Bazar Ramadan KDSM Jalan Kemboja",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Taman Universiti, Iskandar Puteri",
    value: "Bazar Ramadan Taman Universiti, Iskandar Puteri",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Taman Mutiara Rini",
    value: "Bazar Ramadan Taman Mutiara Rini",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Taman Nusa Perintis",
    value: "Bazar Ramadan Taman Nusa Perintis",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Taman Sri Pulai",
    value: "Bazar Ramadan Taman Sri Pulai",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Taman Ungku Tun Aminah",
    value: "Bazar Ramadan Taman Ungku Tun Aminah",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Raman Taman Rinting",
    value: "Bazar Ramadan Raman Taman Rinting",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Taman Kota Masai Jln Bacang",
    value: "Bazar Ramadan Taman Kota Masai Jln Bacang",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Taman Scientex Jln Belatuk",
    value: "Bazar Ramadan Taman Scientex Jln Belatuk",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Taman Scientex Jln Persiaran",
    value: "Bazar Ramadan Taman Scientex Jln Persiaran",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Bandar Pasir Gudang",
    value: "Bazar Ramadan Bandar Pasir Gudang",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Jalan Padi Mahsuri 2, Taman Senai Baru",
    value: "Bazar Ramadan Jalan Padi Mahsuri 2, Taman Senai Baru",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Jalan Idaman 1/13, Taman Desa Idaman",
    value: "Bazar Ramadan Jalan Idaman 1/13, Taman Desa Idaman",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Jalan Enggang, Bandar Putra",
    value: "Bazar Ramadan Jalan Enggang, Bandar Putra",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Jalan Scientex Utama, Kelapa Sawit",
    value: "Bazar Ramadan Jalan Scientex Utama, Kelapa Sawit",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Rezab MPPN Masjid, Pekan Nanas",
    value: "Bazar Ramadan Rezab MPPN Masjid, Pekan Nanas",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Pesta",
    value: "Bazar Ramadan Pesta",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Taman Universiti, Batu Pahat",
    value: "Bazar Ramadan Taman Universiti, Batu Pahat",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Super One ( Muhibbah )",
    value: "Bazar Ramadan Super One ( Muhibbah )",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Yong Peng ( Jalan Bayan )",
    value: "Bazar Ramadan Yong Peng ( Jalan Bayan )",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Parit Sulong",
    value: "Bazar Ramadan Parit Sulong",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Bandar Kluang",
    value: "Bazar Ramadan Bandar Kluang",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Taman Intan",
    value: "Bazar Ramadan Taman Intan",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Bandar T6",
    value: "Bazar Ramadan Bandar T6",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Taman Seri Impian",
    value: "Bazar Ramadan Taman Seri Impian",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Kg. Melayu, Simpang Renggam",
    value: "Bazar Ramadan Kg. Melayu, Simpang Renggam",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Bandar Putra",
    value: "Bazar Ramadan Bandar Putra",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Jementah",
    value: "Bazar Ramadan Jementah",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Paya Pulai",
    value: "Bazar Ramadan Paya Pulai",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Dataran Segamat",
    value: "Bazar Ramadan Dataran Segamat",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Bandar Utama",
    value: "Bazar Ramadan Bandar Utama",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Pekan Sentral, Pekan Nanas",
    value: "Bazar Ramadan Pekan Sentral, Pekan Nanas",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Taman Tun Syed Nasir, Tangkak",
    value: "Bazar Ramadan Taman Tun Syed Nasir, Tangkak",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Kampung Tengah, Muar",
    value: "Bazar Ramadan Kampung Tengah, Muar",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Taman Gemilang",
    value: "Bazar Ramadan Taman Gemilang",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Depan Sks Maslee Express, Parit Masjid",
    value: "Bazar Ramadan Depan Sks Maslee Express, Parit Masjid",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Jalan Utama Air Baloi",
    value: "Bazar Ramadan Jalan Utama Air Baloi",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Mini Stadium Pontian",
    value: "Bazar Ramadan Mini Stadium Pontian",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Kota Jaya",
    value: "Bazar Ramadan Kota Jaya",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Jalan Mawar",
    value: "Bazar Ramadan Jalan Mawar",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Jalan Tun Mahmud, Bandar Tenggara",
    value: "Bazar Ramadan Jalan Tun Mahmud, Bandar Tenggara",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Taman Anggerik",
    value: "Bazar Ramadan Taman Anggerik",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Lubok Kota, Pusat Bandar Kota Tinggi",
    value: "Bazar Ramadan Lubok Kota, Pusat Bandar Kota Tinggi",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Taman Wawasan",
    value: "Bazar Ramadan Taman Wawasan",
    state: "Johor"
  },
  {
    title: "Bazar Ramadan Taman Lautan Biru",
    value: "Bazar Ramadan Taman Lautan Biru",
    state: "Johor"
  },
  {
    title: "bazar Ramadan Jalan Naib Long, Tangkak",
    value: "bazar Ramadan Jalan Naib Long, Tangkak",
    state: "Johor"
  },
  //Sarawak
  {
    title: "Bazar Ramadan Laksar, Bintulu",
    value: "Bazar Ramadan Laksar, Bintulu",
    state: "Sarawak"
  },
  {
    title: "Bazar Ramadan Dinner World, Bintulu",
    value: "Bazar Ramadan Dinner World, Bintulu",
    state: "Sarawak"
  },
  {
    title: "Bazar Ramadan, Sibu",
    value: "Bazar Ramadan, Sibu",
    state: "Sarawak"
  },
  {
    title: "Bazar Ramadan Desa Ilmu, Samaharan",
    value: "Bazar Ramadan Desa Ilmu, Samaharan",
    state: "Sarawak"
  },
  {
    title: "Bazar Ramadan Stutong",
    value: "Bazar Ramadan Stutong",
    state: "Sarawak"
  },
  {
    title: "Bazaar Ramadan Rahmah, Metrocity",
    value: "Bazaar Ramadan Rahmah, Metrocity",
    state: "Sarawak"
  },
  {
    title: "Bazaar Ramadan Lasar Kenyalang, Bintulu",
    value: "Bazaar Ramadan Lasar Kenyalang, Bintulu",
    state: "Sarawak"
  },
  //Sabah
  {
    title: "Bazar Ramadan Bataras Bandar Baru",
    value: "Bazar Ramadan Bataras Bandar Baru",
    state: "Sabah"
  },
  {
    title: "Bazar Ramadan Keningau Mall",
    value: "Bazar Ramadan Keningau Mall",
    state: "Sabah"
  },
  // Labuan
  {
    title: "Bazar Ramadan Labuan Walk",
    value: "Bazar Ramadan Labuan Walk",
    state: "Labuan"
  },
  // TEST
  {
    title: "Bazar Ramadan GEMA",
    value: "Bazar Ramadan GEMA"
  }
];

export const foodType = [
  {
    title: "Nasi Berlauk (nasi ayam/tomato/ambeng/biryani/gulai dll)",
    value: "Nasi Berlauk"
  },
  { title: "Kuih Muih (kek, kuih)", value: "Kuih Muih" },
  {
    title: "Nasi/mee/bihun/kueytiaw Goreng",
    value: "Makanan Berat Goreng"
  },
  {
    title: "Berkuah (laksa, mee kari, bihun sup dll)",
    value: "Makanan Berat Berkuah"
  },
  {
    title: "Goreng2 dan Bakar2 (percik, sate, ayam/sotong/bola goreng dll)",
    value: "Goreng2 dan Bakar2"
  },
  { title: "Western (pizza, spaghetti, dll)", value: "Western" },
  {
    title: "Side Dish (murtabak, roti jala, kebab, roti john dll)",
    value: "Side Dish"
  },
  {
    title: "Lain-lain",
    value: "Others"
  },
  { title: "Minuman (Semua jenis Air)", value: "Minuman" }
];

export const days = [
  "14/03",
  "15/03",
  "16/03",
  "17/03",
  "18/03",
  "19/03",
  "20/03",
  "21/03",
  "22/03",
  "23/03",
  "24/03",
  "25/03",
  "26/03",
  "27/03",
  "28/03",
  "29/03",
  "30/03",
  "31/03",
  "01/04",
  "02/04",
  "03/04",
  "04/04",
  "05/04",
  "06/04",
  "07/04",
  "08/04",
  "09/04",
  "10/04"
];
