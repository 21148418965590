import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Chart } from "chart.js";
import { TextField, CircularProgress } from "@material-ui/core";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
import { useWindowSize } from "../hooks/useWindowSize";
import { findCollection } from "../api/msf";
import { bazarList, days, weathersWithColors } from "../utils/Constant";
import { generateRandomColors } from "../utils/colors";
import { trackEvent, PageEventName } from "../services/analytics";

let RINGGIT = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "MYR",
});

const MsfCollection = () => {
  const { width } = useWindowSize();

  const [selectedBazar, setSelectedBazar] = useState(0);
  const [amountCollectedByDay, setAmountCollectedByDay] = useState([]);
  const [totalCollectedEachDay, setTotalCollectedEachDay] = useState([]);
  const [
    totalCollectedDrinksEachDay,
    setTotalCollectedDrinksEachDay,
  ] = useState([]);

  const [totalAmountCollected, setTotalAmountCollected] = useState(0);
  const [totalAmountCollectedDrinks, setTotalAmountCollectedDrinks] = useState(
    0
  );

  const [bazarNames, setBazarNames] = useState([]);
  const [
    totalAmountCollectedByBazar,
    setTotalAmountCollectedByBazar,
  ] = useState([]);
  const [
    totalAmountCollectedDrinksByBazar,
    setTotalAmountCollectedDrinksByBazar,
  ] = useState([]);

  // Food category
  const [foodCategory1, setFoodCategory1] = useState(0); // Nasi Berlauk (nasi ayam/tomato/ambeng/biryani/gulai dll)
  const [foodCategory2, setFoodCategory2] = useState(0); // Kuih Muih (kek, kuih)
  const [foodCategory3, setFoodCategory3] = useState(0); // Nasi/mee/bihun/kueytiaw Goreng
  const [foodCategory4, setFoodCategory4] = useState(0); // Berkuah (laksa, mee kari, bihun sup dll)
  const [foodCategory5, setFoodCategory5] = useState(0); // Goreng2 dan Bakar2 (percik, sate, ayam/sotong/bola goreng dll)
  const [foodCategory6, setFoodCategory6] = useState(0); // Western (pizza, spaghetti, dll)
  const [foodCategory7, setFoodCategory7] = useState(0); // Side Dish (murtabak, roti jala, kebab, roti john dll)
  const [foodCategory8, setFoodCategory8] = useState(0); // Lain-lain

  const [isLoading, setIsLoading] = useState(true);

  React.useEffect(() => {
    trackEvent(PageEventName.MsfCollection);
  }, []);

  React.useEffect(() => {
    setTimeout(() => setIsLoading(false), 3000);
  }, []);

  React.useEffect(() => {
    const bazars = bazarList.map((b) => b.value);
    const fetchCollection = async () => {
      const mappedCollection = await findCollection(bazars);
      if (mappedCollection) {
        setAmountCollectedByDay(
          mappedCollection.filter(
            (m) => m.total > 0 && m.name !== "Bazar Ramadan GEMA"
          )
        );
      } else {
        alert("Gagal mendapatkan data. Sila Hubungi Admin");
      }
    };
    fetchCollection();
  }, []);

  React.useEffect(() => {
    if (amountCollectedByDay.length > 0) {
      const accumalation = amountCollectedByDay.reduce(
        (a, b) => [
          a[0] + b.total,
          a[1] + b.totalDrinks,
          a[2] + b.foodCategories[0],
          a[3] + b.foodCategories[1],
          a[4] + b.foodCategories[2],
          a[5] + b.foodCategories[3],
          a[6] + b.foodCategories[4],
          a[7] + b.foodCategories[5],
          a[8] + b.foodCategories[6],
          a[9] + b.foodCategories[7],
        ],
        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      );

      const bazarNames = amountCollectedByDay.map(
        (b) =>
          b.name.replace("Bazar Ramadan", "") + " (" + b.activeDays + " hari)"
      );
      setBazarNames(bazarNames);

      const totalAmountCollectedByBazar = amountCollectedByDay.map(
        (b) => b.total
      );
      setTotalAmountCollectedByBazar(totalAmountCollectedByBazar);

      const totalAmountCollectedDrinksByBazar = amountCollectedByDay.map(
        (b) => b.totalDrinks
      );
      setTotalAmountCollectedDrinksByBazar(totalAmountCollectedDrinksByBazar);

      setTotalAmountCollected(accumalation[0]);
      setTotalAmountCollectedDrinks(accumalation[1]);
      setFoodCategory1(accumalation[2].toFixed(2));
      setFoodCategory2(accumalation[3].toFixed(2));
      setFoodCategory3(accumalation[4].toFixed(2));
      setFoodCategory4(accumalation[5].toFixed(2));
      setFoodCategory5(accumalation[6].toFixed(2));
      setFoodCategory6(accumalation[7].toFixed(2));
      setFoodCategory7(accumalation[8].toFixed(2));
      setFoodCategory8(accumalation[9].toFixed(2));
    }
  }, [amountCollectedByDay]);

  // each bazar each day chart
  React.useEffect(() => {
    let TotalEachDay = [];
    let TotalDrinksEachDay = [];
    days.forEach((day, index) => {
      let collectedAmount = 0;
      let bazarCount = 0;
      let collectedDrinks = 0;
      amountCollectedByDay.forEach((bz, i) => {
        const context = document.getElementById("chart-" + i);
        if (context) {
          new Chart(context, {
            type: "bar",
            data: {
              labels: days,
              datasets: [
                {
                  label: "Kutipan Makanan (KG)",
                  data: bz.data,
                  backgroundColor: bz.colors,
                },
                {
                  label: "Kutipan Minuman (KG)",
                  data: bz.drinks,
                  backgroundColor: bz.drinkColors,
                },
              ],
            },
            options: {
              legend: {
                display: true,
                labels: {
                  fontColor: "black",
                  generateLabels: function () {
                    if (weathersWithColors) {
                      return weathersWithColors.map(function (weather, index) {
                        return {
                          text: weather.name,
                          fillStyle: weather.color,
                          hidden: false,
                          index: index,
                        };
                      });
                    }
                    return [];
                  },
                },
              },
              scales: {
                yAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: "Kutipan (KG)",
                    },
                  },
                ],
              },
            },
          });
        }
        collectedAmount += bz.data[index];
        if (bz.data[index] > 0) bazarCount++;
        collectedDrinks += bz.drinks[index];
      });
      TotalEachDay.push({
        total: Number(collectedAmount.toFixed(2)),
        count: bazarCount,
      });
      TotalDrinksEachDay.push(Number(collectedDrinks.toFixed(2)));
    });
    setTotalCollectedEachDay(TotalEachDay);
    setTotalCollectedDrinksEachDay(TotalDrinksEachDay);
  }, [amountCollectedByDay, totalAmountCollectedByBazar, selectedBazar]);

  // day chart
  React.useEffect(() => {
    const colors = generateRandomColors(days.length);
    if (
      totalCollectedEachDay.length === 28 &&
      totalCollectedDrinksEachDay.length === 28
    ) {
      const ct = document.getElementById("dayChart");
      if (ct) {
        new Chart(ct, {
          type: "bar",
          data: {
            labels: totalCollectedEachDay.map(
              (t, index) => `${days[index]} (${t.count} bazar)`
            ),
            datasets: [
              {
                label: "Kutipan Makanan (KG)",
                data: totalCollectedEachDay.map((t) => t.total),
                backgroundColor: colors,
              },
              {
                label: "Kutipan Minuman (KG)",
                data: totalCollectedDrinksEachDay,
                backgroundColor: colors.map((c) => c.replace("1)", "0.3")),
              },
            ],
          },
          options: {
            legend: {
              display: false,
            },
            scales: {
              yAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: "Kutipan (KG)",
                  },
                },
              ],
            },
          },
        });
      }
    }
  }, [totalCollectedEachDay, totalCollectedDrinksEachDay]);

  // bazar chart
  React.useEffect(() => {
    // slice bazarNames array into first 40
    const colors = generateRandomColors(bazarNames.slice(0, 40).length);
    const ctx = document.getElementById("bazarChart");
    if (ctx) {
      new Chart(ctx, {
        type: "bar",
        data: {
          labels: bazarNames.slice(0, 40),
          datasets: [
            {
              label: "Kutipan Makanan (KG)",
              data: totalAmountCollectedByBazar.slice(0, 40),
              backgroundColor: colors,
            },
            {
              label: "Kutipan Minuman (KG)",
              data: totalAmountCollectedDrinksByBazar.slice(0, 40),
              backgroundColor: colors.map((c) => c.replace("1)", "0.4")),
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Kutipan (KG)",
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    }
  }, [
    bazarNames,
    totalAmountCollectedByBazar,
    totalAmountCollectedDrinksByBazar,
  ]);

  React.useEffect(() => {
    const colors = generateRandomColors(bazarNames.slice(40, 80).length);
    const ctx = document.getElementById("bazarChart2");
    if (ctx) {
      new Chart(ctx, {
        type: "bar",
        data: {
          labels: bazarNames.slice(40, 80),
          datasets: [
            {
              label: "Kutipan Makanan (KG)",
              data: totalAmountCollectedByBazar.slice(40, 80),
              backgroundColor: colors,
            },
            {
              label: "Kutipan Minuman (KG)",
              data: totalAmountCollectedDrinksByBazar.slice(40, 80),
              backgroundColor: colors.map((c) => c.replace("1)", "0.4")),
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Kutipan (KG)",
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    }
  }, [
    bazarNames,
    totalAmountCollectedByBazar,
    totalAmountCollectedDrinksByBazar,
  ]);

  React.useEffect(() => {
    const colors = generateRandomColors(bazarNames.slice(80, 120).length);
    const ctx = document.getElementById("bazarChart3");
    if (ctx) {
      new Chart(ctx, {
        type: "bar",
        data: {
          labels: bazarNames.slice(80, 120),
          datasets: [
            {
              label: "Kutipan Makanan (KG)",
              data: totalAmountCollectedByBazar.slice(80, 120),
              backgroundColor: colors,
            },
            {
              label: "Kutipan Minuman (KG)",
              data: totalAmountCollectedDrinksByBazar.slice(80, 120),
              backgroundColor: colors.map((c) => c.replace("1)", "0.4")),
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Kutipan (KG)",
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    }
  }, [
    bazarNames,
    totalAmountCollectedByBazar,
    totalAmountCollectedDrinksByBazar,
  ]);

  React.useEffect(() => {
    const colors = generateRandomColors(bazarNames.slice(120, 160).length);
    const ctx = document.getElementById("bazarChart4");
    if (ctx) {
      new Chart(ctx, {
        type: "bar",
        data: {
          labels: bazarNames.slice(120, 160),
          datasets: [
            {
              label: "Kutipan Makanan (KG)",
              data: totalAmountCollectedByBazar.slice(120, 160),
              backgroundColor: colors,
            },
            {
              label: "Kutipan Minuman (KG)",
              data: totalAmountCollectedDrinksByBazar.slice(120, 160),
              backgroundColor: colors.map((c) => c.replace("1)", "0.4")),
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Kutipan (KG)",
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    }
  }, [
    bazarNames,
    totalAmountCollectedByBazar,
    totalAmountCollectedDrinksByBazar,
  ]);

  return (
    <div className="body_wrapper">
      {/* <Breadcrumb
        breadcrumbClass="breadcrumb_area"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="Sign Up"
        Pdescription="Why I say old chap that is spiffing off his nut arse pear shaped plastered Jeffrey bodge barney some dodgy.!!"
      /> */}
      <div className="event_img align-items-right">
        <img
          width="100%"
          src={require("../img/attend/mysavefood2.jpeg")}
          alt=""
        />
      </div>
      <div>
        <div className="container text-center mt-3 mb-3">
          <div className="row">
            <div className="col-md-4">
              <Link to="/collection/msf">Maklumat Kutipan</Link>
            </div>
            <div className="col-md-4">
              <Link to="/volunteer/msf">Maklumat Sukarelawan</Link>
            </div>
            <div className="col-md-4">
              <Link to="/map/msf">Peta Kutipan</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center">
        {isLoading && (
          <>
            <CircularProgress />
            <p>Please wait while charts are loading...</p>
          </>
        )}
      </div>
      <div
        className="container"
        style={{ opacity: isLoading ? 0.1 : 1, fontSize: width > 765 ? 14 : 7 }}
      >
        <div className="text-center mt-5 mb-5">
          <h3 className="mb-2 mt-2">
            Jumlah Kutipan Keseluruhan:{" "}
            {Number(totalAmountCollected + totalAmountCollectedDrinks).toFixed(
              2
            )}{" "}
            KG
          </h3>
          <h6>
            {" "}
            Makanan ({totalAmountCollected.toFixed(2)} KG) | Minuman (
            {totalAmountCollectedDrinks.toFixed(2)} KG)
          </h6>
        </div>
        <div className="text-center">
          <h6>Kutipan Setakat {moment().format("DD MMMM yy")}</h6>
          <table style={{ fontSize: 14 }} className="table table-bordered">
            <thead>
              <tr className="text-center">
                <td>Jenis Makanan</td>
                <td>Jumlah Kutipan (KG)</td>
                <td>Food Value (RM)</td>
                <td>Emission Prevented (KG)</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Nasi Berlauk (nasi ayam/tomato/ambeng/biryani/gulai dll)
                </td>
                <td className="text-center">{foodCategory1}</td>
                <td className="text-center">
                  {RINGGIT.format(foodCategory1 * 19.25)}
                </td>
                <td className="text-center">
                  {(foodCategory1 * 2.7).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td>Kuih Muih (kek, kuih)</td>
                <td className="text-center">{foodCategory2}</td>
                <td className="text-center">
                  {RINGGIT.format(foodCategory2 * 22.0)}
                </td>
                <td className="text-center">
                  {(foodCategory2 * 2).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td>Nasi/mee/bihun/kueytiaw Goreng</td>
                <td className="text-center">{foodCategory3}</td>
                <td className="text-center">
                  {RINGGIT.format(foodCategory3 * 18.33)}
                </td>
                <td className="text-center">
                  {(foodCategory3 * 3.5).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td>Berkuah (laksa, mee kari, bihun sup dll)</td>
                <td className="text-center">{foodCategory4}</td>
                <td className="text-center">
                  {RINGGIT.format(foodCategory4 * 16.5)}
                </td>
                <td className="text-center">
                  {(foodCategory4 * 3).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td>
                  Goreng2 dan Bakar2 (percik, sate, ayam/sotong/bola goreng dll)
                </td>
                <td className="text-center">{foodCategory5}</td>
                <td className="text-center">
                  {RINGGIT.format(foodCategory5 * 33.0)}
                </td>
                <td className="text-center">
                  {(foodCategory5 * 4).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td>Western (pizza, spaghetti, dll)</td>
                <td className="text-center">{foodCategory6}</td>
                <td className="text-center">
                  {RINGGIT.format(foodCategory6 * 55.0)}
                </td>
                <td className="text-center">
                  {(foodCategory6 * 4.5).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td>Side Dish (murtabak, roti jala, kebab, roti john dll)</td>
                <td className="text-center">{foodCategory7}</td>
                <td className="text-center">
                  {RINGGIT.format(foodCategory7 * 26.4)}
                </td>
                <td className="text-center">
                  {(foodCategory7 * 2).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td>Lain-lain</td>
                <td className="text-center">{foodCategory8}</td>
                <td className="text-center">
                  {RINGGIT.format(foodCategory8 * 22.0)}
                </td>
                <td className="text-center">
                  {(foodCategory8 * 2.5).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td>Minuman</td>
                <td className="text-center">
                  {totalAmountCollectedDrinks.toFixed(2)}
                </td>
                <td className="text-center">
                  {RINGGIT.format(totalAmountCollectedDrinks.toFixed(2) * 6.0)}
                </td>
                <td className="text-center">
                  {(totalAmountCollectedDrinks * 1.5).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td>
                  <h6>JUMLAH</h6>
                </td>
                <td className="text-center">
                  <h6>
                    {" "}
                    {Number(
                      totalAmountCollected + totalAmountCollectedDrinks
                    ).toFixed(2)}
                  </h6>
                </td>
                <td className="text-center">
                  <h6>
                    {RINGGIT.format(
                      foodCategory1 * 19.25 +
                        foodCategory2 * 22.0 +
                        foodCategory3 * 18.33 +
                        foodCategory4 * 16.5 +
                        foodCategory5 * 33.0 +
                        foodCategory6 * 55.0 +
                        foodCategory7 * 26.4 +
                        foodCategory8 * 22.0 +
                        totalAmountCollectedDrinks.toFixed(2) * 6.0
                    )}
                  </h6>
                </td>
                <td className="text-center">
                  <h6>
                    {(
                      foodCategory1 * 2.7 +
                      foodCategory2 * 2 +
                      foodCategory3 * 3.5 +
                      foodCategory4 * 3 +
                      foodCategory5 * 4 +
                      foodCategory6 * 4.5 +
                      foodCategory7 * 2 +
                      foodCategory8 * 2.5 +
                      totalAmountCollectedDrinks.toFixed(2) * 1.5
                    ).toFixed(2)}
                  </h6>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="mb-5 text-center">
          <div>
            <h6>
              Bilangan Bazar Yang Sudah Mula:{" "}
              {totalAmountCollectedByBazar.length}
            </h6>
            <Carousel
              className="app_screenshot_slider"
              autoPlay={true}
              interval="3000"
              showArrows={true}
              emulateTouch={true}
            >
              <div>
                <canvas id="bazarChart" />
              </div>
              <div>
                <canvas id="bazarChart2" />
              </div>
              <div>
                <canvas id="bazarChart3" />
              </div>
              <div>
                <canvas id="bazarChart4" />
              </div>
            </Carousel>
          </div>
          <div>
            <h6>
              Bilangan Hari Operasi:{" "}
              {totalCollectedEachDay.filter((t) => t.total > 0).length}
            </h6>
            <canvas id="dayChart" />
          </div>
        </div>
        <div className="mb-3">
          <Autocomplete
            options={bazarList}
            getOptionLabel={(option) => option.title}
            onChange={(_e, val) => setSelectedBazar(val.value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Lokasi Bazar"
                margin="normal"
                variant="outlined"
                value={selectedBazar}
                required
                fullWidth
              />
            )}
          />
        </div>
        <div>
          {amountCollectedByDay &&
            amountCollectedByDay.length > 0 &&
            amountCollectedByDay
              .sort((a, b) => b.total - a.total)
              .map((bz, i) => {
                return (
                  selectedBazar === bz.name && (
                    <div key={i}>
                      <h5>
                        {i + 1}. {bz.name}
                      </h5>
                      <div className="mb-5">
                        <canvas id={"chart-" + i} />
                      </div>
                      <table
                        style={{ fontSize: 6 }}
                        className="table table-bordered mb-5"
                      >
                        <thead>
                          <tr>
                            {days.map((day, i) => (
                              <td key={i}>{day}</td>
                            ))}
                            <td>Jumlah</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr key={i}>
                            {bz.data.map((d, i) => {
                              return (
                                <td key={i}>
                                  {Number(d + bz.drinks[i]).toFixed(2)}
                                </td>
                              );
                            })}
                            <td>
                              {Number(bz.total + bz.totalDrinks).toFixed(2)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="row">
                        <div className="col-12 mb-3">
                          <h6 className="text-center">
                            Kutipan Harian Mengikut Kategori Makanan (
                            {bz.foodCategoriesByDate
                              ? bz.foodCategoriesByDate.length
                              : 0}{" "}
                            hari)
                          </h6>
                        </div>
                        {bz.foodCategoriesByDate &&
                          bz.foodCategoriesByDate.map((f, i) => (
                            <div className="col-md-6 col-sm-12">
                              <p className="text-center">
                                Tarikh Operasi: <strong>{f.date}</strong>
                              </p>
                              <table
                                style={{ fontSize: 14 }}
                                className="table table-bordered"
                              >
                                <thead>
                                  <tr className="text-center">
                                    <td>Jenis Makanan</td>
                                    <td>Jumlah Kutipan (KG)</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Nasi Berlauk</td>
                                    <td className="text-center">
                                      {Number(f.f1).toFixed(2)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Kuih Muih</td>
                                    <td className="text-center">
                                      {Number(f.f2).toFixed(2)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Nasi/mee/bihun/kueytiaw Goreng</td>
                                    <td className="text-center">
                                      {Number(f.f3).toFixed(2)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Berkuah</td>
                                    <td className="text-center">
                                      {Number(f.f4).toFixed(2)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Goreng2 dan Bakar2</td>
                                    <td className="text-center">
                                      {Number(f.f5).toFixed(2)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Western</td>
                                    <td className="text-center">
                                      {Number(f.f6).toFixed(2)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Side Dish</td>
                                    <td className="text-center">
                                      {Number(f.f7).toFixed(2)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Lain-lain</td>
                                    <td className="text-center">
                                      {Number(f.f8).toFixed(2)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Minuman</td>
                                    <td className="text-center">
                                      {Number(f.d).toFixed(2)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <h6>JUMLAH</h6>
                                    </td>
                                    <td className="text-center">
                                      <h6>{Number(f.t).toFixed(2)}</h6>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          ))}
                      </div>
                    </div>
                  )
                );
              })}
        </div>
        <div className="text-center mt-5 mb-5">
          <h6>Data Kutipan Mengikut Bazar</h6>
          <table style={{ fontSize: 14 }} className="table table-bordered">
            <thead>
              <tr>
                <td rowSpan={2} align="center">
                  Nama Bazar
                </td>
                <td colSpan={3}>Kumulatif</td>
              </tr>
              <tr>
                <td> Makanan (KG)</td>
                <td>Minuman (KG)</td>
                <td> Jumlah (KG)</td>
              </tr>
            </thead>
            <tbody>
              {amountCollectedByDay &&
                amountCollectedByDay.length > 0 &&
                amountCollectedByDay.map((bz, i) => {
                  if (bz.total > 0) {
                    return (
                      <tr key={i}>
                        <td>{bz.name}</td>
                        <td>{bz.total.toFixed(2)}</td>
                        <td>{bz.totalDrinks.toFixed(2)}</td>
                        <td>
                          {(Number(bz.total) + Number(bz.totalDrinks)).toFixed(
                            2
                          )}
                        </td>
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })}
            </tbody>
          </table>
        </div>
      </div>
      <Footer FooterData={FooterData} />
    </div>
  );
};
export default MsfCollection;
