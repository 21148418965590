import axios from "axios";

const urlAxios = axios.create({
  baseURL: process.env.REACT_APP_SHORTURL_SERVICE_URL,
});

export async function getUrlByShorten(id) {
  try {
    const { data } = await urlAxios.get(`/shorten/${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getFileByShorten(id) {
  try {
    //const { data } = await urlAxios.get(`/shorten/${id}`);
    if (id === "msf22") {
      return {
        width: 420,
        height: 584,
        link:
          "https://res.cloudinary.com/denr13kdi/image/upload/v1650341758/flipbook/3e79d929a8a5db5f9cbc792931a470ce099eed50202203_ozneex.pdf",
      };
    }
    if (id === "intro22") {
      return {
        width: 560,
        height: 320,
        link:
          "https://res.cloudinary.com/denr13kdi/image/upload/v1655956489/flipbook/intro22_gwccii.pdf",
      };
    }
    if (id === "msf23-kitbook") {
      return {
        width: 420,
        height: 584,
        link: "https://www.sigmagema.com/msf23-kitbook.pdf",
      };
    }
    if (id === "msf23-report") {
      return {
        width: 420,
        height: 584,
        link: "https://www.sigmagema.com/msf23-report.pdf",
      };
    }
    if (id === "profile") {
      return {
        width: 420,
        height: 584,
        link: "https://www.sigmagema.com/profile.pdf",
      };
    }
    return null;
  } catch (error) {
    console.log(error);
  }
}
