export const generateColors = (number) => {
  let randomColors = [];
  for (let i = 0; i < number; i++) {
    const colorString = "#" + Math.floor(Math.random() * 16777215).toString(16);
    randomColors.push(colorString);
  }
  return randomColors;
};

export function generateRandomColors(numColors) {
  var colors = [];

  for (var i = 0; i < numColors; i++) {
    var r = Math.floor(Math.random() * 256);
    var g = Math.floor(Math.random() * 256);
    var b = Math.floor(Math.random() * 256);
    var color = "rgb(" + r + ", " + g + ", " + b + ",1)";
    colors.push(color);
  }

  return colors;
}
