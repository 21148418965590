import React from 'react';

const SecurityCustomerLogo =()=>{
    return(
        <section className="security_customers_logo_area">
            <div className="container">
                <div className="hosting_title analytices_title text-center">
                    <h2 className="wow fadeInUp">Matlamat Pembangunan Lestari <br/>Pemuda GEMA</h2>
                </div>
                <div className="security_inner">
                    <img className="p_absoulte security_leaf_left wow fadeInDown" data-wow-delay="0.8s" src={require("../img/home-security/leaf_clients2.png")} alt=""/>
                    <img className="p_absoulte security_leaf_right wow fadeInDown" data-wow-delay="0.8s" src={require("../img/home-security/leaf_clients1.png")} alt=""/>
                    <div className="row">
                        <div className="col-lg col-sm-4 col-6">
                            <a href="/#" className="analytices_logo wow fadeInUp"><img src={require("../img/passion-clusters/sdg/sdg1.png")} alt=""/></a>
                        </div>
                        <div className="col-lg col-sm-4 col-6">
                            <a href="/#" className="analytices_logo wow fadeInUp"><img src={require("../img/passion-clusters/sdg/sdg2.png")} alt=""/></a>
                        </div>
                        <div className="col-lg col-sm-4 col-6">
                            <a href="/#" className="analytices_logo wow fadeInUp" ><img src={require("../img/passion-clusters/sdg/sdg3.png")} alt=""/></a>
                        </div>
                        <div className="col-lg col-sm-4 col-6">
                            <a href="/#" className="analytices_logo wow fadeInUp" ><img src={require("../img/passion-clusters/sdg/sdg4.png")} alt=""/></a>
                        </div>
                        <div className="col-lg col-sm-4 col-6">
                            <a href="/#" className="analytices_logo wow fadeInUp" ><img src={require("../img/passion-clusters/sdg/sdg8.png")} alt=""/></a>
                        </div>
                        <div className="col-lg col-sm-4 col-6">
                            <a href="/#" className="analytices_logo wow fadeInUp" ><img src={require("../img/passion-clusters/sdg/sdg11.png")} alt=""/></a>
                        </div>
                        <div className="col-lg col-sm-4 col-6">
                            <a href="/#" className="analytices_logo wow fadeInUp"><img src={require("../img/passion-clusters/sdg/sdg12.png")} alt=""/></a>
                        </div>
                        <div className="col-lg col-sm-4 col-6">
                            <a href="/#" className="analytices_logo wow fadeInUp" ><img src={require("../img/passion-clusters/sdg/sdg16.png")} alt=""/></a>
                        </div>
                        <div className="col-lg col-sm-4 col-6">
                            <a href="/#" className="analytices_logo wow fadeInUp" ><img src={require("../img/passion-clusters/sdg/sdg17.png")} alt=""/></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default SecurityCustomerLogo;