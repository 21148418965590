import React, { Component } from "react";
import { Formik } from "formik";
import { TextField, CircularProgress } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {
  createNewAttendance,
  createRegisteredAttendance,
} from "../../api/attendance";

class ScheduleTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      failure: false,
    };
  }

  handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ success: false });
  };

  handleCloseFailure = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ failure: false });
  };

  render() {
    return (
      <section className="event_schedule_area sec_pad">
        <div className="container">
          <div className="event_schedule_inner">
            <ul className="nav nav-tabs event_tab" role="tablist">
              <li className="nav-item wow fadeInUp" data-wow-delay="0.4s">
                <a
                  className="nav-link active"
                  id="one-tab"
                  data-toggle="tab"
                  href="#one"
                  role="tab"
                  aria-controls="one"
                  aria-selected="true"
                >
                  <h5>
                    Ahli/Rakan Gema <span>(Telah Daftar)</span>
                  </h5>
                </a>
              </li>
              <li className="nav-item wow fadeInUp" data-wow-delay="0.6s">
                <a
                  className="nav-link"
                  id="two-tab"
                  data-toggle="tab"
                  href="#two"
                  role="tab"
                  aria-controls="two"
                  aria-selected="false"
                >
                  <h5>
                    Sukarelawan Awam<span>(Belum Daftar/Pertama Kali)</span>
                  </h5>
                </a>
              </li>
            </ul>
            <div className="tab-content event_tab_content">
              <div
                className="tab-pane fade show active"
                id="one"
                role="tabpanel"
                aria-labelledby="one-tab"
              >
                <Formik
                  initialValues={{ nationalId: "" }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.nationalId) {
                      errors.nationalId = "No kad Pengenalan diperlukan";
                    }
                    return errors;
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);
                    const created = await createRegisteredAttendance({
                      ...values,
                      activityId: this.props.activityId,
                    });
                    setSubmitting(false);
                    if (created) {
                      this.setState({ success: true });
                    } else {
                      this.setState({ failure: true });
                    }
                  }}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <p className="mt-3">
                        Masukkan kad pengenalan untuk mencatat kehadiran
                      </p>
                      <div className="col-md-12">
                        <TextField
                          label="No. Kad Pengenalan"
                          required
                          margin="normal"
                          name="nationalId"
                          variant="outlined"
                          placeholder="eg: 900101011111"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.nationalId}
                          fullWidth
                        />
                        <br />
                        {errors.nationalId && (
                          <p style={{ color: "red" }}>{errors.nationalId}</p>
                        )}
                      </div>
                      <div className="col-md-12">
                        {!isSubmitting ? (
                          <button
                            type="submit"
                            className="seo_btn seo_btn_two btn_hover wow"
                            style={{ width: "100%" }}
                            disabled={this.state.success}
                          >
                            Hadir
                          </button>
                        ) : (
                          <CircularProgress />
                        )}
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
              <div
                className="tab-pane fade"
                id="two"
                role="tabpanel"
                aria-labelledby="two-tab"
              >
                <Formik
                  initialValues={{
                    nationalId: "",
                    name: "",
                    phone: "",
                    email: "",
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.name) {
                      errors.name = "Nama diperlukan";
                    }
                    if (!values.nationalId) {
                      errors.nationalId = "No kad Pengenalan diperlukan";
                    }
                    if (!values.phone) {
                      errors.phone = "No telefon diperlukan";
                    }
                    if (!values.email) {
                      errors.email = "Email diperlukan";
                    }
                    return errors;
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);
                    const created = await createNewAttendance(
                      values,
                      this.props.activityId
                    );
                    setSubmitting(false);
                    if (created) {
                      this.setState({ success: true });
                    } else {
                      this.setState({ failure: true });
                    }
                  }}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <p className="mt-3">
                        Masukkan Maklumat untuk mencatat kehadiran
                      </p>
                      <div className="col-md-12">
                        <TextField
                          name="name"
                          label="Nama Penuh"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                        />
                        <br />
                        {errors.name && (
                          <p style={{ color: "red" }}>{errors.name}</p>
                        )}
                      </div>
                      <div className="col-md-12">
                        <TextField
                          name="nationalId"
                          label="No Kad Pengenalan"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.nationalId}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                        />
                        <br />
                        {errors.nationalId && (
                          <p style={{ color: "red" }}>{errors.nationalId}</p>
                        )}
                      </div>
                      <div className="col-md-12">
                        <TextField
                          name="phone"
                          label="No Telefon Bimbit"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.phone}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                        />
                        <br />
                        {errors.phone && (
                          <p style={{ color: "red" }}>{errors.phone}</p>
                        )}
                      </div>
                      <div className="col-md-12">
                        <TextField
                          name="email"
                          label="E-mel"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                        />
                        <br />
                        {errors.email && (
                          <p style={{ color: "red" }}>{errors.email}</p>
                        )}
                      </div>
                      <div className="col-md-12">
                        {!isSubmitting ? (
                          <button
                            type="submit"
                            className="seo_btn seo_btn_two btn_hover wow"
                            style={{ width: "100%" }}
                            disabled={this.state.success}
                          >
                            Hadir
                          </button>
                        ) : (
                          <CircularProgress />
                        )}
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.state.success}
          autoHideDuration={6000}
          onClose={this.handleCloseSuccess}
        >
          <MuiAlert onClose={this.handleCloseSuccess} severity="success">
            Kehadiran anda telah didaftarkan!
          </MuiAlert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.state.failure}
          autoHideDuration={6000}
          onClose={this.handleCloseFailure}
        >
          <MuiAlert onClose={this.handleCloseFailure} severity="error">
            Kehadiran gagal didaftarkan!
          </MuiAlert>
        </Snackbar>
      </section>
    );
  }
}
export default ScheduleTab;
