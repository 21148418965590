import React, { Component } from "react";
import ErpIconitem from "./ErpIconitem";
import Slider from "react-slick";

class ErpFeaturesitem extends Component {
  render() {
    let { rowClass } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      speed: 2000,
      fade: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className={`row erp_item_features ${rowClass}`}>
        <div className="col-lg-6">
          <div className="erp_features_img_two">
            <Slider className="app_screenshot_slider" {...settings}>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/horizon/hz1.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/horizon/hz11.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/horizon/hz4.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/horizon/hz6.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/horizon/hz5.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/horizon/hz7.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/horizon/hz3.png")}
                    alt=""
                  />
                </div>
              </div>
            </Slider>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="erp_content_two">
            <div className="hosting_title erp_title">
              <h2>HORIZON</h2>
              <p>
                Pemuda GEMA berhasrat mengajak belia yang sukakan aktiviti lasak
                dan aktif dalam aktiviti "outdoor" untuk sama-sama menyertai
                pasukan HORIZON yang sentiasa bersemangat dalam menganjurkan
                pelbagai aktiviti luar dan lasak. <br />
                Istimewanya pasukan HORIZON adalah mereka sentiasa mementingkan
                nilai-nilai kebersamaan dan manusiawi, selain memberi latihan
                bagi membangunkan kemahiran mandiri.
              </p>
            </div>
            <ErpIconitem
              iconName="icon_ribbon_alt"
              erpText="Mendaki Gunung & Bukit"
            />
            <ErpIconitem 
              iconName="icon_ribbon_alt" 
              erpText="Mendaki Gunung G7" />
            <ErpIconitem
              iconName="icon_ribbon_alt"
              erpText="Water Rafting"
            />
            <ErpIconitem 
              iconName="icon_ribbon_alt" 
              erpText="Foodgasm" />
            <ErpIconitem 
              iconName="icon_ribbon_alt" 
              erpText="Basic Camp" />
            <ErpIconitem
              iconName="icon_ribbon_alt"
              erpText="Wall and Rock Climbing"
            />
            <ErpIconitem 
              iconName="icon_ribbon_alt" 
              erpText="Candat Sotong" />
          </div>
        </div>
      </div>
    );
  }
}
export default ErpFeaturesitem;
