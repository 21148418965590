import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";

class EventFeaturesItem extends Component {
  render() {
    let { url, title, ptext, poster, btn } = this.props;
    return (
      <div className="col-lg-4 col-sm-6 mb-4">
        <div className="event_features_item text-center wow fadeInUp">
          <img width="100" src={require("../../img/logo.png")} alt="logo" />
          <a href={url}>
            <h5>{title}</h5>
          </a>
          {poster ? (
            <div>
              <img className="center" src={poster} alt="" width="225" />
            </div>
          ) : (
            <div style={{ maxHeight: 300, overflow: "hidden" }}>
              {ReactHtmlParser(ptext)}
            </div>
          )}
          <a href={url} className="e_features_btn">
            {btn} <i className="icon_plus"></i>
          </a>
        </div>
      </div>
    );
  }
}

export default EventFeaturesItem;
