import { atom } from "recoil";

const localStorageEffect = (key) => ({ setSelf, onSet }) => {
  const savedValue = localStorage.getItem(key);
  if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }

  onSet((newValue, _, isReset) => {
    isReset
      ? localStorage.removeItem(key)
      : localStorage.setItem(key, JSON.stringify(newValue));
  });
};

const MultiLinkState = atom({
  key: "multiLinkState", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
  effects: [localStorageEffect("multiLink")],
});

export default MultiLinkState;
