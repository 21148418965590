import axios from "axios";

const token = process.env.REACT_APP_MAIN_API_TOKEN;

const mainAPIClient = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_MAIN_API_URL
      : process.env.REACT_APP_MAIN_API_URL_LOCAL,
  headers: {
    "X-Request-Agent": "gema-web",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export async function createNewLeader(leaderDto) {
  try {
    const response = await mainAPIClient.post("/msf/leader", leaderDto);
    if (response.status === 201) {
      return { created: true };
    }
    return { created: false, data: response.data };
  } catch ({ response }) {
    return {
      created: false,
      data: response?.data || "An unexpected error occurred",
    };
  }
}

export async function signInLeader(leaderDto) {
  try {
    const response = await mainAPIClient.post("/msf/auth", leaderDto);

    if (response.status === 201) {
      return { authorized: true, data: response.data };
    }
    return { authorized: false, data: response.data };
  } catch ({ response }) {
    return {
      authorized: false,
      data: response?.data || "An unexpected error occurred",
    };
  }
}

export async function forgotPassword(leaderDto) {
  try {
    const response = await mainAPIClient.post("/msf/forgot", leaderDto);

    if (response.status === 201) {
      return { sent: true, data: response.data };
    }
    return { sent: false, data: response.data };
  } catch ({ response }) {
    return {
      sent: false,
      data: response?.data || "An unexpected error occurred",
    };
  }
}

export async function resetPassword(leaderDto) {
  try {
    const response = await mainAPIClient.post("/msf/reset", leaderDto);

    if (response.status === 201) {
      return { updated: true, data: response.data };
    }
    return { updated: false, data: response.data };
  } catch ({ response }) {
    return {
      updated: false,
      data: response?.data || "An unexpected error occurred",
    };
  }
}

export async function updateLeaderStatus(id, leaderDto) {
  try {
    const response = await mainAPIClient.put(`/msf/leader/${id}`, leaderDto);

    if (response.status === 200) {
      return { updated: true, data: response.data };
    }
    return { updated: false, data: response.data };
  } catch ({ response }) {
    return {
      updated: false,
      data: response?.data || "An unexpected error occurred",
    };
  }
}

export async function getActivityByDate(user, date) {
  try {
    const response = await mainAPIClient.get(`/msf/activity/${user}/${date}`);
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (error) {
    return null;
  }
}

export async function findCollection(bazars) {
  try {
    const response = await mainAPIClient.post(`/msf/collection/bazars`, {
      bazars,
    });
    if (response.status === 201) {
      return response.data;
    }
    return null;
  } catch (error) {
    return null;
  }
}

export async function findVolunteers(bazars) {
  try {
    const response = await mainAPIClient.post(`/msf/volunteer/date`, {
      bazars,
    });
    if (response.status === 201) {
      return response.data;
    }
    return null;
  } catch (error) {
    return null;
  }
}

export async function createCollection(collectionDto) {
  try {
    const response = await mainAPIClient.post("/msf/collection", collectionDto);
    if (response.status === 201) {
      return { updated: true, data: response.data };
    }
    return { updated: false, data: response.data };
  } catch ({ response }) {
    return {
      updated: false,
      data: response?.data || "An unexpected error occurred",
    };
  }
}

export async function createNewProvider(providerDto) {
  try {
    const response = await mainAPIClient.post("/msf/provider", providerDto);
    if (response.status === 201) {
      return true;
    }
  } catch (error) {
    return false;
  }
}

export async function registerVolunteer(volunteerDto) {
  try {
    const response = await mainAPIClient.post("/msf/volunteer", volunteerDto);
    if (response.status === 201) {
      return { created: true };
    }
    return { created: false, data: response.data };
  } catch ({ response }) {
    return {
      created: false,
      data: response?.data || "An unexpected error occurred",
    };
  }
}
