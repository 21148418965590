import React from "react";
import { getUrlByShorten } from "../api/shorturl";

export default function Redirect(props) {
  const [broken, setBroken] = React.useState(false);
  const shorten = props.match.params.id;
  React.useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await getUrlByShorten(shorten);
        if (!data.url) {
          throw new Error("Link is Missing");
        }
        setTimeout(() => (window.location.href = data.url), 1000);
      } catch (error) {
        console.log(error);
        setTimeout(() => setBroken(true), 1000);
      }
    }
    fetchData();
  }, [shorten]);
  return (
    <section>
      <div className="div_centered">
        {broken ? (
          <div className="flex_column">
            <img src={require("../img/new/error.png")} width="256" alt="" />
            <h2 className="mt-4">Opps! Link is inactive or broken :(</h2>
            <a href="/" className="about_btn btn_hover mt-3">
              Back to Homepage <i className="arrow_right"></i>
            </a>
          </div>
        ) : (
          <div className="flex_column">
            <lottie-player
              src="https://assets6.lottiefiles.com/packages/lf20_38snabcg.json"
              background="transparent"
              speed="1.2"
              style={{ width: 256 }}
              loop
              autoplay
            />
            <h2>Redirecting to your requested page...</h2>
          </div>
        )}
      </div>
    </section>
  );
}
