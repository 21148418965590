const FooterData = {
  CompanyWidget: [
    {
      id: 1,
      title: "Get In Touch",
      description: "Don’t miss any updates of our new events",
    },
  ],
  SocialLinks: [
    {
      id: 1,
      title: "Social links",
      menuItems: [
        {
          id: 1,
          url: "https://www.facebook.com/Pertubuhan.Pemuda.GEMA.Malaysia",
          icon: "ti-facebook",
        },
        {
          id: 2,
          url: "https://twitter.com/pemudagema",
          icon: "ti-twitter",
        },
        {
          id: 3,
          url: "https://instagram.com/pemuda_gema",
          icon: "ti-instagram",
        },
      ],
    },
  ],
  copywrite: "© 2024 Pertubuhan Pemuda Gema Malaysia",
};
export default FooterData;
