import React from "react";
import {
  TextField,
  CircularProgress,
  Tab,
  Tabs,
  Checkbox,
  DialogActions,
  Paper,
  Switch,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import MaterialTable from "material-table";
import { Chart } from "chart.js";
import { QRCodeSVG } from "qrcode.react";
import { Formik } from "formik";
import qs from "query-string";
import moment from "moment-timezone";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FaTrashAlt } from "react-icons/fa";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  signInLeader,
  updateLeaderStatus,
  createCollection,
  getActivityByDate,
  findCollection,
  forgotPassword,
  resetPassword,
} from "../api/msf";
import { confirmAttendedUser } from "../api/attendance";
import {
  weathers,
  foodType,
  days,
  weathersWithColors,
} from "../utils/Constant";
import { trackEvent, PageEventName } from "../services/analytics";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
import { TabPanel } from "../components/TabPanel";
import exportToExcel from "../utils/exportToExcel";

const Msf = () => {
  React.useEffect(() => {
    trackEvent(PageEventName.MsfReport);
  }, []);

  React.useEffect(() => {
    const query = qs.parse(window.location.search);
    if (query.token) {
      setAuthToken(query.token);
      setIsReset(true);
    }
  }, []);

  const today = moment().local();
  const [value, setValue] = React.useState(0);
  const [user, setUser] = React.useState(null);
  const [act, setAct] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(
    today.format("yyyy-MM-DDThh:mm")
  );
  const [collect, setCollect] = React.useState(null);
  const [weather, setWeather] = React.useState(null);
  const [collectedItem, setCollectedItem] = React.useState([]);
  const [type, setType] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [checkedUser, setCheckedUser] = React.useState([]);

  //State PIC
  const [bazarLeaders, setBazarLeaders] = React.useState([]);
  const [volunteers, setVolunteers] = React.useState([]);
  const [mappedCollection, setMappedCollection] = React.useState([]);

  const [loginError, setLoginError] = React.useState("");
  const [isChangingDate, setIsChangingDate] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [help, setHelp] = React.useState(false);

  const [isForgot, setIsForgot] = React.useState(false);
  const [isReset, setIsReset] = React.useState(false);
  const [authToken, setAuthToken] = React.useState("");

  React.useEffect(() => {
    const fetchCollection = async (bazarName) => {
      const bazars = [].concat(bazarName);
      const cl = await findCollection(bazars);
      if (!cl || cl.length <= 0) {
        console("Tiada rekod lagi untuk bazar ini");
      }
      setMappedCollection(cl);
    };
    if (user && user.bazar && user.leaderRole === 1) {
      fetchCollection(user.bazar);
    }
  }, [collect, value, user]);

  React.useEffect(() => {
    const context = document.getElementById("collectionChart");
    if (context) {
      const bz = mappedCollection[0];
      new Chart(context, {
        type: "bar",
        data: {
          labels: days,
          datasets: [
            {
              label: "Kutipan Makanan (KG)",
              data: bz.data,
              backgroundColor: bz.colors,
            },
            {
              label: "Kutipan Minuman (KG)",
              data: bz.drinks,
              backgroundColor: bz.drinkColors,
            },
          ],
        },
        options: {
          legend: {
            display: true,
            labels: {
              fontColor: "black",
              generateLabels: function () {
                if (weathersWithColors) {
                  return weathersWithColors.map(function (weather, index) {
                    return {
                      text: weather.name,
                      fillStyle: weather.color,
                      hidden: false,
                      index: index,
                    };
                  });
                }
                return [];
              },
            },
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Kutipan (KG)",
                },
              },
            ],
          },
        },
      });
    }
  }, [mappedCollection]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setTimeout(() => {
      setMsg("");
    }, 5000);
  };

  const toggleLeaderActivation = async (id, value) => {
    const { updated, data } = await updateLeaderStatus(id, { approved: value });
    if (updated) {
      // replace object in bazarLeaders with the update leader
      const index = bazarLeaders.findIndex((b) => b.id === id);
      const updatedLeaders = [...bazarLeaders];
      updatedLeaders[index] = data;
      setBazarLeaders(updatedLeaders);
      setMsg("Kemaskini Berjaya");
    } else {
      setMsg(data);
    }
  };

  // React.useEffect(() => {
  //   if (
  //     sessionStorage.getItem("user") &&
  //     sessionStorage.getItem("user") !== "undefined"
  //   ) {
  //     setUser(JSON.parse(sessionStorage.getItem("user")) || null);
  //   }
  //   if (
  //     sessionStorage.getItem("volunteers") &&
  //     sessionStorage.getItem("volunteers") !== "undefined"
  //   ) {
  //     setVolunteers(JSON.parse(sessionStorage.getItem("volunteers")) || []);
  //   }
  //   if (
  //     sessionStorage.getItem("bazarLeaders") &&
  //     sessionStorage.getItem("bazarLeaders") !== "undefined"
  //   ) {
  //     setBazarLeaders(JSON.parse(sessionStorage.getItem("bazarLeaders")) || []);
  //   }
  // }, []);

  return (
    <div className="body_wrapper">
      <>
        <Dialog open={!user}>
          <DialogTitle className="text-center">
            Portal Ketua Bazar & PIC Negeri
          </DialogTitle>
          <DialogContent>
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              onSubmit={async (values) => {
                const { authorized, data } = await signInLeader({
                  ...values,
                  date: today.format("YYYY-MM-DD"),
                });
                if (!authorized || !data) {
                  setLoginError(
                    (data && data.message) || "something went wrong"
                  );
                  setTimeout(() => {
                    setLoginError("");
                  }, 3500);
                  return;
                }
                const { leader, activity, collections } = data;
                if (leader) {
                  setUser(leader);
                  sessionStorage.setItem("user", JSON.stringify(leader));
                }
                if (
                  (leader && leader.leaderRole === 0) ||
                  leader.leaderRole === 2
                ) {
                  setBazarLeaders(data.bazarLeaders);
                  sessionStorage.setItem(
                    "bazarLeaders",
                    JSON.stringify(data.bazarLeaders)
                  );
                  setVolunteers(data.volunteers);
                  sessionStorage.setItem(
                    "volunteers",
                    JSON.stringify(data.volunteers)
                  );
                  return;
                }
                // bazar leader flow
                if (activity) {
                  setAct(activity);
                }
                if (collections) {
                  const cl = collections.find((c) => {
                    return (
                      moment(c.date).format("YYYY-MM-DD") ===
                      today.format("YYYY-MM-DD")
                    );
                  });
                  if (cl) {
                    setCollect(cl);
                    setWeather(cl.weather);
                    setCollectedItem(cl.collectedItem);
                  }
                }
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="col-md-12">
                    <TextField
                      name="email"
                      label="Email"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                    />
                    <br />
                    {errors.email && (
                      <p style={{ color: "red" }}>{errors.email}</p>
                    )}
                  </div>
                  <div className="col-md-12">
                    <TextField
                      name="password"
                      label="Kata Laluan"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                    />
                    <br />
                    {errors.password && (
                      <p style={{ color: "red" }}>{errors.password}</p>
                    )}
                  </div>
                  <div className="col-md-12">
                    {loginError && <p style={{ color: "red" }}>{loginError}</p>}
                  </div>
                  <a className="m-3" href="#" onClick={() => setIsForgot(true)}>
                    Lupa Kata Laluan?
                  </a>
                  <div className="col-md-12">
                    {!isSubmitting ? (
                      <button
                        type="submit"
                        className="seo_btn seo_btn_two btn_hover wow mt-3"
                        style={{ width: "100%" }}
                      >
                        LOG MASUK
                      </button>
                    ) : (
                      <CircularProgress />
                    )}
                  </div>
                </form>
              )}
            </Formik>
          </DialogContent>
          <DialogActions>
            <a className="m-3" href="/registration/msf?role=1">
              Tiada Akaun? Daftar
            </a>
          </DialogActions>
        </Dialog>
      </>
      <>
        {/* Dialog showing form to send email for password reset if isForgot true */}
        <Dialog open={isForgot}>
          <DialogTitle className="text-center">Lupa Kata Laluan</DialogTitle>
          <DialogContent>
            <Formik
              initialValues={{
                email: "",
              }}
              onSubmit={async (values) => {
                const { sent, data } = await forgotPassword(values);
                if (!sent || !data) {
                  setLoginError(
                    (data && data.message) || "something went wrong"
                  );
                  setTimeout(() => {
                    setLoginError("");
                  }, 3500);
                  return;
                }
                setMsg("Email telah dihantar");
                setIsForgot(false);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="col-md-12">
                    <TextField
                      name="email"
                      label="Email"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                    />
                    <br />
                    {errors.email && (
                      <p style={{ color: "red" }}>{errors.email}</p>
                    )}
                  </div>
                  <div className="col-md-12">
                    {loginError && <p style={{ color: "red" }}>{loginError}</p>}
                  </div>
                  <div className="col-md-12">
                    {!isSubmitting ? (
                      <button
                        type="submit"
                        className="seo_btn seo_btn_two btn_hover wow mt-3"
                        style={{ width: "100%" }}
                      >
                        Hantar
                      </button>
                    ) : (
                      <CircularProgress />
                    )}
                  </div>
                </form>
              )}
            </Formik>
          </DialogContent>
          <DialogActions>
            <a className="m-3" href="#" onClick={() => setIsForgot(false)}>
              Kembali
            </a>
          </DialogActions>
        </Dialog>
      </>
      <>
        {/* Dialog showing form to reset password if isReset true */}
        <Dialog open={isReset}>
          <DialogTitle className="text-center">
            Tetapkan Kata Laluan Baru
          </DialogTitle>
          <DialogContent>
            <Formik
              initialValues={{
                password: "",
              }}
              onSubmit={async (values) => {
                const { updated, data } = await resetPassword({
                  password: values.password,
                  token: authToken,
                });
                if (!updated || !data) {
                  setLoginError(
                    (data && data.message) || "something went wrong"
                  );
                  setTimeout(() => {
                    setLoginError("");
                  }, 3500);
                  return;
                }
                setMsg("Kata Laluan telah ditukar");
                setIsReset(false);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="col-md-12">
                    <TextField
                      name="password"
                      label="Kata Laluan"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      required
                    />
                    <br />
                    {errors.password && (
                      <p style={{ color: "red" }}>{errors.password}</p>
                    )}
                  </div>
                  <div className="col-md-12">
                    {loginError && <p style={{ color: "red" }}>{loginError}</p>}
                  </div>
                  <div className="col-md-12">
                    {!isSubmitting ? (
                      <button
                        type="submit"
                        className="seo_btn seo_btn_two btn_hover wow mt-3"
                        style={{ width: "100%" }}
                      >
                        Hantar
                      </button>
                    ) : (
                      <CircularProgress />
                    )}
                  </div>
                </form>
              )}
            </Formik>
          </DialogContent>
          <DialogActions>
            <a className="m-3" href="#" onClick={() => setIsReset(false)}>
              Kembali
            </a>
          </DialogActions>
        </Dialog>
      </>
      <>
        <Dialog open={help} onClose={() => setHelp(false)}>
          <DialogTitle className="text-center">Bantuan</DialogTitle>
          <DialogContent>
            {user && user.leaderRole === 0 && (
              <>
                <p>
                  <strong>Mastersheet PIC Negeri</strong>
                  <a
                    href="https://docs.google.com/spreadsheets/d/1IiKOcOCW2Z9OxlB3s0l4u-tHQ_LS_OV9/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>Link</span>
                  </a>
                </p>
              </>
            )}
            <p>
              <strong>Dokumen Sokongan</strong>
              <a
                href="https://www.sigmagema.com/links/msf24-docs"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Link</span>
              </a>
            </p>

            <p>
              <strong>KIT grafik design</strong>
              <a
                href="https://www.sigmagema.com/links/msf24-design"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Link</span>
              </a>
            </p>
          </DialogContent>
          <DialogActions>
            <a className="m-3" href="#" onClick={() => setHelp(false)}>
              Tutup
            </a>
          </DialogActions>
        </Dialog>
      </>
      <div className="event_img align-items-right">
        <img
          width="100%"
          src={require("../img/attend/mysavefood.jpeg")}
          alt=""
        />
      </div>
      <div>
        {user && (
          <>
            <a
              href="#"
              className="ml-5"
              style={{
                color: "blue",
                textDecoration: "underline",
                float: "left",
              }}
              onClick={() => setHelp(true)}
            >
              Bantuan
            </a>
            <a
              href="#"
              className="mr-5"
              style={{
                color: "blue",
                textDecoration: "underline",
                float: "right",
              }}
              onClick={() => setUser(null)}
            >
              Log Keluar
            </a>
          </>
        )}
      </div>
      {user && user.leaderRole === 1 ? (
        <>
          <div className="container mt_70 mb_70">
            <h1 className="mb-5 text-center">Laporan Harian Ketua Bazar</h1>
            <h3>
              Nama: <strong>{user ? user.name : "-"}</strong>
            </h3>
            <h3>
              Tempat: <strong>{user ? user.bazar : "-"}</strong>
            </h3>
            <h3>
              Rekod untuk Tarikh: {isChangingDate && <CircularProgress />}
            </h3>
            <TextField
              label="Tarikh"
              margin="normal"
              type="datetime-local"
              variant="outlined"
              onChange={async (e) => {
                //check if e.target.value is valid date
                if (
                  !moment(e.target.value, "YYYY-MM-DDThh:mm", true).isValid()
                ) {
                  return;
                }
                setSelectedDate(e.target.value);
                setIsChangingDate(true);
                const data = await getActivityByDate(
                  user.id,
                  moment(e.target.value).format("YYYY-MM-DD")
                );
                setIsChangingDate(false);
                if (data) {
                  const { leader, activity, collection } = data;
                  if (leader) {
                    setUser(leader);
                  } else {
                    setUser(null);
                  }
                  if (activity) {
                    setAct(activity);
                  } else {
                    setAct(null);
                  }
                  if (collection) {
                    setCollect(collection);
                    setWeather(collection.weather);
                    setCollectedItem(collection.collectedItem);
                  } else {
                    setCollect(null);
                    setWeather("");
                    setCollectedItem([]);
                  }
                } else {
                  alert("Tiada rekod untuk tarikh ini. Cuba lagi");
                  setAct(null);
                  setCollect(null);
                  setWeather("");
                  setCollectedItem([]);
                }
              }}
              value={selectedDate}
              fullWidth
            />
          </div>
          <div className="container mt_70 mb_70">
            <Tabs value={value} onChange={handleTabChange} centered>
              <Tab label="Kehadiran Sukarelawan" />
              <Tab label="kutipan Makanan" />
            </Tabs>

            <TabPanel className="text-center" value={value} index={0}>
              <div>
                <p className="mt-3">QR Kehadiran:</p>
                {act && (
                  <QRCodeSVG value={`sigmagema.com/attend?id=${act.id}`} />
                )}
                <p className="mt-3">
                  {act ? (
                    <a
                      href={`/attend?id=${act.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >{`sigmagema.com/attend?id=${act.id}`}</a>
                  ) : (
                    "-"
                  )}
                </p>
                <p>
                  {act && `${moment.utc(act.startDate).format("DD MMMM yy")}`}
                </p>
              </div>
              <table className="table table-bordered mt-5">
                <thead>
                  <tr>
                    <td>BIL</td>
                    <td className="p-3">NAMA</td>
                    <td>MASA BERMULA</td>
                    <td>SAHKAN</td>
                  </tr>
                </thead>
                {act &&
                  act.attendances &&
                  act.attendances.map((a, index) =>
                    a.attendanceStatus === 0 ? (
                      <tbody key={a.id}>
                        <tr>
                          <td>{index + 1}.</td>
                          <td className="p-3">{a.attendee.name}</td>
                          <td>{`${moment(a.startTime).format(
                            "DD MMMM yy (hh:mm a)"
                          )}`}</td>
                          <td>
                            <Checkbox
                              onChange={(event) => {
                                if (event.target.checked) {
                                  setCheckedUser([...checkedUser, a.id]);
                                } else {
                                  setCheckedUser(
                                    checkedUser.filter((c) => c !== a.id)
                                  );
                                }
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    ) : null
                  )}
              </table>
              <div className="col-md-12 text-center">
                {true ? (
                  <button
                    onClick={() => {
                      const response = confirmAttendedUser(checkedUser);
                      if (response) {
                        setCheckedUser([]);
                        alert("Pengesahan berjaya");
                      }
                    }}
                    className="seo_btn seo_btn_two btn_hover mt-5"
                  >
                    Sahkan
                  </button>
                ) : (
                  <CircularProgress />
                )}
              </div>
              <div className="col-md-12 mt-5">
                <h6>Ahli yang telah sah hadir</h6>
                {act &&
                  act.attendances &&
                  act.attendances.map((a, index) =>
                    a.attendanceStatus === 1 ? (
                      <span key={index}>{a.attendee.name},</span>
                    ) : null
                  )}
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Formik
                initialValues={{
                  weather: weather,
                }}
                enableReinitialize
                onSubmit={async (values) => {
                  console.log(moment(selectedDate).format("YYYY-MM-DD"));
                  if (values.weather === "" || values.weather === undefined) {
                    alert("Sila pilih keadaan cuaca");
                    return;
                  }
                  if (collectedItem.length <= 0) {
                    alert("Sila tambah makanan yang dikutip");
                    return;
                  }
                  const { updated, data } = await createCollection(
                    collect
                      ? {
                          id: collect.id,
                          weather: weather,
                          collectedItem,
                          leader: user,
                        }
                      : {
                          weather: weather,
                          collectedItem,
                          leader: user,
                          date: moment(selectedDate).format("YYYY-MM-DD"),
                        }
                  );
                  if (updated) {
                    setCollect(data);
                    alert("Penghantaran berjaya");
                  } else {
                    alert(data);
                  }
                }}
              >
                {({ handleBlur, handleSubmit, isSubmitting }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="row mb-5 mt-5">
                      <div className="col-md-12 mb-3">
                        <h6>Tarikh Kutipan</h6>
                        {collect
                          ? moment(collect.date).format("DD MMMM yy") //+`(${collect.id})`
                          : "Tiada Maklumat yang dicatat"}
                      </div>
                      <div className="col-md-12">
                        <h6>Keadaan Cuaca</h6>
                      </div>
                      <div className="col-md-12">
                        <Autocomplete
                          options={weathers}
                          getOptionLabel={(option) => option.title}
                          onChange={(_e, val) => {
                            setWeather(val ? val.value : undefined);
                          }}
                          value={weathers.find((w) => w.title === weather)}
                          onBlur={handleBlur}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Keadaan Cuaca"
                              margin="normal"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-12">
                        {" "}
                        <h6>Jenis dan Berat Makanan atau Minuman</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        {collectedItem && collectedItem.length > 0 && (
                          <div>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <td>BIL</td>
                                  <td className="p-3">JENIS</td>
                                  <td>Berat Kutipan (KG)</td>
                                  <td></td>
                                </tr>
                              </thead>
                              {collectedItem.map((item, index) => (
                                <tbody key={index}>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td className="p-3">{item.type}</td>
                                    <td>{item.amount} KG</td>
                                    <td>
                                      <FaTrashAlt
                                        color="action"
                                        onClick={() => {
                                          const filtered = collectedItem.filter(
                                            (val) => val.type !== item.type
                                          );
                                          setCollectedItem(filtered);
                                        }}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              ))}
                            </table>
                          </div>
                        )}
                      </div>
                      <div className="col-md-8">
                        <Autocomplete
                          options={foodType}
                          onChange={(_e, val) =>
                            setType(val ? val.value : undefined)
                          }
                          getOptionLabel={(option) => option.title}
                          onBlur={handleBlur}
                          value={foodType.find((w) => w.title === type)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Jenis Makanan"
                              margin="normal"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      </div>
                      <div className="col-md-2">
                        <TextField
                          label="Berat (KG)"
                          margin="normal"
                          variant="outlined"
                          onChange={(e) =>
                            setAmount(e.target.value.replace(/[^\d.-]/g, ""))
                          }
                          onBlur={handleBlur}
                          value={amount}
                          fullWidth
                        />
                      </div>
                      <div className="col-md-2">
                        <button
                          className="btn_three mt-3"
                          type="button"
                          onClick={() => {
                            if (type === "") {
                              alert("Sila isi jenis makanan");
                              return;
                            }
                            if (amount === "") {
                              alert("Sila isi berat kutipan");
                              return;
                            }
                            // check if the type already exist append the amount
                            const exist = collectedItem.find(
                              (val) => val.type === type
                            );
                            if (exist) {
                              // get curent amount for the type and add the new amount
                              const totalAmount =
                                parseFloat(exist.amount) + parseFloat(amount);
                              const filtered = collectedItem.filter(
                                (val) => val.type !== type
                              );
                              setCollectedItem([
                                ...filtered,
                                { type, amount: totalAmount },
                              ]);
                            } else {
                              setCollectedItem([
                                ...collectedItem,
                                { type, amount: parseFloat(amount) },
                              ]);
                            }
                            setAmount("");
                          }}
                        >
                          Tambah
                        </button>
                      </div>
                    </div>
                    <div className="col-md-12 text-center">
                      {!isSubmitting ? (
                        <button
                          type="submit"
                          className="seo_btn seo_btn_two btn_hover mt-5"
                        >
                          KEMASKINI
                        </button>
                      ) : (
                        <CircularProgress />
                      )}
                    </div>
                  </form>
                )}
              </Formik>
              <div>
                <h3 className="mt-5 mb-3">Maklumat Kutipan</h3>
                <div className="mb-5">
                  <canvas id="collectionChart" />
                </div>
              </div>
            </TabPanel>
          </div>
        </>
      ) : (
        <>
          <div className="container mt_70 mb_70">
            <h1 className="mb-5 text-center">
              {user && user.leaderRole === 2
                ? "Portal Sekretariat"
                : "Portal PIC Negeri"}
            </h1>
            <span>
              <h3>
                Nama: <strong>{user ? user.name : "-"}</strong>
              </h3>
            </span>

            <h3>
              Negeri: <strong>{user ? user.state : "-"}</strong>
            </h3>
          </div>
          <div className="container mt_70 mb_70">
            <h3 className="mb-3">Senarai Ketua Bazar</h3>
            {bazarLeaders && bazarLeaders.length > 0 ? (
              <Paper>
                <MaterialTable
                  columns={[
                    {
                      field: "id",
                      title: "No",
                      width: 75,
                      render: (rowData) => (
                        <p>{bazarLeaders.indexOf(rowData) + 1}</p>
                      ),
                    },
                    { field: "name", title: "Nama", width: 400 },
                    { field: "bazar", title: "Lokasi Bazar", width: 500 },
                    {
                      field: "leaderStatus",
                      title: "Keaktifan",
                      width: 200,
                      render: (rowData) => (
                        <Switch
                          color="primary"
                          checked={rowData.approved}
                          onChange={() =>
                            toggleLeaderActivation(
                              rowData.id,
                              !rowData.approved
                            )
                          }
                        />
                      ),
                    },
                  ]}
                  data={bazarLeaders}
                  title={`Bilangan: ${bazarLeaders.length}`}
                />
              </Paper>
            ) : (
              <p> Tiada rekod</p>
            )}
          </div>
          <div className="container mt_70 mb_70">
            <h3 className="mb-3">Senarai Sukarelawan</h3>
            {volunteers && volunteers.length > 0 ? (
              <Paper>
                <MaterialTable
                  style={{ overflowX: "auto" }}
                  columns={[
                    {
                      field: "id",
                      title: "No",
                      width: 75,
                      render: (rowData) => (
                        <p>{volunteers.indexOf(rowData) + 1}</p>
                      ),
                    },
                    { field: "name", title: "Nama", width: 500 },
                  ]}
                  data={volunteers}
                  title={`Bilangan: ${volunteers.length}`}
                  options={{
                    actionsColumnIndex: -1,
                    exportButton: true,
                    exportCsv: (columns, data) => {
                      const st = data.map((volunteer) => {
                        if (user && user.leaderRole === 2) {
                          return {
                            Nama: volunteer.name,
                            No_IC: volunteer.nationalId,
                            Emel: volunteer.email,
                            No_Telefon: volunteer.phone,
                            Alamat: volunteer.address,
                            Negeri: volunteer.state,
                            Jantina: volunteer.gender,
                            Bangsa: volunteer.race,
                            Umur: volunteer.age,
                            Agama: volunteer.religion,
                            Pendidikan: volunteer.education,
                            Pekerjaan: volunteer.occupation,
                          };
                        } else {
                          return {
                            Nama: volunteer.name,
                            Emel: volunteer.email,
                            No_Telefon: volunteer.phone,
                          };
                        }
                      });
                      exportToExcel(st, "senarai_sukarelawan_msf");
                    },
                  }}
                />
              </Paper>
            ) : (
              <p> Tiada rekod</p>
            )}
          </div>
        </>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={msg.length > 0}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
      >
        <MuiAlert
          onClose={handleCloseSuccess}
          severity={
            msg === "Kemaskini Berjaya" ||
            msg === "Email telah dihantar" ||
            msg === "Kata Laluan telah ditukar"
              ? "success"
              : "error"
          }
        >
          {msg}
        </MuiAlert>
      </Snackbar>
      <Footer FooterData={FooterData} />
    </div>
  );
};

export default Msf;
