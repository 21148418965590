import axios from "axios";

const urlAxios = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_MAIN_API_URL
      : process.env.REACT_APP_MAIN_API_URL_LOCAL,
});

export async function getUrlByPath(id) {
  try {
    const { data } = await urlAxios.get(`/multilink/path/${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
}
