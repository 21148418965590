import React from "react";
import GoogleMapReact from "google-map-react";

const AnyReactComponent = ({ title, amount }) => (
  <div style={{ width: 259 }}>
    <div className="row_container">
      <lottie-player
        src="https://assets7.lottiefiles.com/packages/lf20_FThwqb45DR.json"
        background="transparent"
        speed="0.2"
        style={{ width: 64, height: 64 }}
        loop
        autoplay
      />
      <p
        style={{
          color: "white",
          textShadow: "0 0 3px #FF0000, 0 0 5px #0000FF",
        }}
      >
        {title} ({amount}KG)
      </p>
    </div>
  </div>
);

const MapContainer = ({ bazarList }) => {
  const [defaultLocation, setDefaultLocation] = React.useState({
    lat: 4.12144,
    lng: 109.10156,
  });
  const [defaultZoom, setDefaultZoom] = React.useState(6);

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "100vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAP_KEY,
        }}
        defaultCenter={defaultLocation}
        defaultZoom={defaultZoom}
        // heatmap={{
        //   positions: [
        //     {
        //       lat: 3.140853,
        //       lng: 101.693207,
        //       weight: 2,
        //     },
        //     {
        //       lat: 3.140853,
        //       lng: 102.693207,
        //       weight: 4,
        //     },
        //   ],
        // }}
      >
        {bazarList &&
          bazarList.map((bazar) =>
            bazar.coordinate ? (
              <AnyReactComponent
                key={bazar.title}
                onClick={() => {
                  console.log("clicked");
                  setDefaultLocation({ lat: 3.140853, lng: 101.693207 });
                  setDefaultZoom(4);
                }}
                amount={bazar.total}
                lat={bazar.coordinate.lat}
                lng={bazar.coordinate.lng}
                title={bazar.title}
              />
            ) : null
          )}
      </GoogleMapReact>
    </div>
  );
};

export default MapContainer;
