import React from "react";
import Fade from "react-reveal/Fade";

const Service = () => {
  return (
    <React.Fragment>
      <section className="seo_service_area sec_pad">
        <div className="container">
          <div className="row seo_service_info">
            <Fade bottom duration={500}>
              <div className="col-lg-4 col-md-6">
                <div className="seo_service_item">
                  <img
                    src={require("../../img/icon/interview.png")}
                    width={64}
                    height={64}
                    alt="Empower"
                  />
                  <a href=".#">
                    <h4>Empower</h4>
                  </a>
                  <p>Pemuda Gema memangkinkan kecemerlangan individu</p>
                  {/* <a href=".#">
                    <i className="arrow_right"></i>
                  </a> */}
                </div>
              </div>
            </Fade>
            <Fade bottom duration={700}>
              <div className="col-lg-4 col-md-6">
                <div className="seo_service_item">
                  <img
                    src={require("../../img/icon/respect.png")}
                    width={64}
                    height={64}
                    alt="Respect"
                  />
                  <a href=".#">
                    <h4>Respect</h4>
                  </a>
                  <p>Pemuda Gema komited untuk menyemai semangat perpaduan</p>
                  {/* <a href=".#">
                    <i className="arrow_right"></i>
                  </a> */}
                </div>
              </div>
            </Fade>
            <Fade bottom duration={1000}>
              <div className="col-lg-4 col-md-6">
                <div className="seo_service_item">
                  <img
                    src={require("../../img/icon/guitar.png")}
                    width={64}
                    height={64}
                    alt="Awesome"
                  />
                  <a href=".#">
                    <h4>Awesome</h4>
                  </a>
                  <p>
                    Pemuda Gema membawa platform untuk menjana perubahan positif
                  </p>
                  {/* <a href=".#">
                    <i className="arrow_right"></i>
                  </a> */}
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Service;
