import React, { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
import PdfViewer from "../components/PdfViewer";
import bg from "../img/hosting/map.png";
import { getFileByShorten } from "../api/shorturl";

const Pdf = (props) => {
  const [file, setFile] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const path = props.match.params.id;

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await getFileByShorten(path);
      if (result) {
        setFile(result);
      } else {
        window.location.href = "/404";
      }
    };
    fetchData();
  }, [path]);

  // is loading true in 5 second
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2500);
    return () => clearTimeout(timer);
  });

  return (
    <div className="body_wrapper">
      <section
        className="multilink_area"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <a href="/">
          <img src={require("../img/logo.png")} alt="logo" />
        </a>
        {isLoading ? (
          <div
            className="mt-5 mb-5 text-center w-100"
            style={{ backgroundImage: `url(${bg})` }}
          >
            <CircularProgress className="" />
          </div>
        ) : (
          <PdfViewer file={file} path={path} />
        )}
      </section>

      <Footer FooterData={FooterData} />
    </div>
  );
};

export default Pdf;
