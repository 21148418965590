import React, {Component} from 'react';
import Fade from 'react-reveal/Fade' 

class SubscribeDonation extends Component {
    render(){
        return(
            <React.Fragment>
                <section className="seo_partner_logo_area sec_pad2">
                    <div className= "mb_50"></div>
                    <div className="container">
                        <div className="seo_sec_title text-center mb_50">
                            <Fade bottom><h3>Sumbangan anda akan membangun dan memperkasakan projek-projek berstatus lestari Pemuda GEMA.</h3></Fade>
                        </div>
                        <div className="partner_logo_area_four">
                            <div className="row partner_info ">
                                <div className="logo_item">
                                    <img src={require('../img/passion-clusters/logo/heroes.png')} height={"55px"} alt=""/>
                                </div>
                                <div className="logo_item">
                                    <img src={require('../img/passion-clusters/logo/green_heroes.png')} height={"55px"} alt=""/>
                                </div>
                                <div className="logo_item">
                                    <img src={require('../img/passion-clusters/logo/-ve_heroes.png')} height={"55px"} alt=""/>
                                </div>
                                <div className="logo_item">
                                    <img src={require('../img/passion-clusters/logo/muzik_gema.png')} height={"65px"} alt=""/>
                                </div>
                                <div className="logo_item">
                                    <img src={require('../img/passion-clusters/logo/horizon.png')} height={"80px"} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default SubscribeDonation;