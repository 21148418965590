import React from "react";
import Summary from "../components/Msf/Summary";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
import ChatClients from "../components/ChatClients";

const MsfMain = () => {
  return (
    <div className="body_wrapper">
      {/* <Breadcrumb
        breadcrumbClass="breadcrumb_area"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="Sign Up"
        Pdescription="Why I say old chap that is spiffing off his nut arse pear shaped plastered Jeffrey bodge barney some dodgy.!!"
      /> */}
      <div className="event_img align-items-right">
        <img
          width="100%"
          src={require("../img/attend/mysavefood2.jpeg")}
          alt=""
        />
      </div>
      <Summary />
      <ChatClients />
      {/* <div className="container mt-3 mb-3">
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-2141897620403515"
          data-ad-slot="6293601485"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </div> */}
      <Footer FooterData={FooterData} />
    </div>
  );
};
export default MsfMain;
