import React, { Component } from "react";
import ErpIconitem from "./ErpIconitem";
import Slider from "react-slick";

class ErpFeaturesitem extends Component {
  render() {
    let { rowClass } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      speed: 2000,
      arrows: false,
      fade: true,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className={`row erp_item_features ${rowClass}`}>
        <div className="col-lg-6">
          <div className="erp_features_img_two">
            <Slider className="app_screenshot_slider" {...settings}>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/-ve-heroes/-ve7.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/-ve-heroes/-ve2.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/-ve-heroes/-ve3.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/-ve-heroes/-ve4.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/-ve-heroes/-ve5.png")}
                    alt=""
                  />
                </div>
              </div>
            </Slider>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="erp_content_two">
            <div className="hosting_title erp_title">
              <h2>-VE HEROES</h2>
              <p>
                Negative Heroes dilancarkan pada Oktober 2019 bagi
                memperjuangkan isu diskriminasi terhadap bekas penagih di
                samping membantu mereka pulih dan bebas daripada ketagihan
                dadah. <br />
                Usaha dalam menggerakkan -ve HEROES dimulakan dan dihidupkan
                oleh pasukan Negative Heroes di Kelantan yang terdiri daripada
                sukarelawan Pemuda GEMA dan bekas penagih dadah dengan mendapat
                sokongan padu daripada agensi-agensi berkaitan seperti Pejabat
                Kesihatan Daerah, Agensi Anti Dadah Kebangsaan, Jabatan Agama
                Islam Negeri dan PDRM.
              </p>
            </div>
            <ErpIconitem
              iconName="icon_ribbon_alt"
              erpText="Projek We Ride Together"
            />
            <ErpIconitem
              iconName="icon_ribbon_alt"
              erpText="Kisah Dari Lorong: Mr X"
            />
          </div>
        </div>
      </div>
    );
  }
}
export default ErpFeaturesitem;
