import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Chart } from "chart.js";
import { CircularProgress } from "@material-ui/core";
import { findCollection } from "../api/msf";
import MapContainer from "../components/Map";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
import { trackEvent, PageEventName } from "../services/analytics";
import { bazarList, states } from "../utils/Constant";
import { generateRandomColors } from "../utils/colors";

let RINGGIT = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "MYR",
});

const MsfMap = () => {
  React.useEffect(() => {
    trackEvent(PageEventName.MsfMap);
  }, []);

  React.useEffect(() => {
    setTimeout(() => setIsLoading(false), 3000);
  }, []);

  const [amountCollectedByBazar, setAmountCollectedByBazar] = useState([]);
  //const [amountCollectedByState, setAmountCollectedByState] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  React.useEffect(() => {
    const bazars = bazarList.map((b) => b.value);
    const fetchCollection = async () => {
      const mappedCollection = await findCollection(bazars);
      if (mappedCollection) {
        const mapped = bazarList.map((b) => {
          const bazar = mappedCollection.find((m) => m.name === b.value);
          return {
            ...b,
            f1: bazar && bazar.foodCategories ? bazar.foodCategories[0] : 0,
            f2: bazar && bazar.foodCategories ? bazar.foodCategories[1] : 0,
            f3: bazar && bazar.foodCategories ? bazar.foodCategories[2] : 0,
            f4: bazar && bazar.foodCategories ? bazar.foodCategories[3] : 0,
            f5: bazar && bazar.foodCategories ? bazar.foodCategories[4] : 0,
            f6: bazar && bazar.foodCategories ? bazar.foodCategories[5] : 0,
            f7: bazar && bazar.foodCategories ? bazar.foodCategories[6] : 0,
            f8: bazar && bazar.foodCategories ? bazar.foodCategories[7] : 0,
            f: bazar && bazar.total ? bazar.total : 0,
            d: bazar && bazar.totalDrinks ? bazar.totalDrinks : 0,
            activeDays: bazar && bazar.activeDays ? bazar.activeDays : 0,
            total:
              bazar && bazar.total
                ? Number(bazar.total + bazar.totalDrinks).toFixed(2)
                : 0,
          };
        });
        setAmountCollectedByBazar(
          mapped.filter((m) => m.total > 0 && m.title !== "Bazar Ramadhan GEMA")
        );
      } else {
        alert("Gagal mendapatkan data. Sila Hubungi Admin");
      }
    };
    fetchCollection();
  }, []);

  React.useEffect(() => {
    const food1CollectedByState = states.map((s) => {
      return amountCollectedByBazar.reduce((prev, curr) => {
        if (curr.state === s) {
          return (prev += Number(curr.f1));
        }
        return prev;
      }, 0);
    });
    const food2CollectedByState = states.map((s) => {
      return amountCollectedByBazar.reduce((prev, curr) => {
        if (curr.state === s) {
          return (prev += Number(curr.f2));
        }
        return prev;
      }, 0);
    });
    const food3CollectedByState = states.map((s) => {
      return amountCollectedByBazar.reduce((prev, curr) => {
        if (curr.state === s) {
          return (prev += Number(curr.f3));
        }
        return prev;
      }, 0);
    });
    const food4CollectedByState = states.map((s) => {
      return amountCollectedByBazar.reduce((prev, curr) => {
        if (curr.state === s) {
          return (prev += Number(curr.f4));
        }
        return prev;
      }, 0);
    });
    const food5CollectedByState = states.map((s) => {
      return amountCollectedByBazar.reduce((prev, curr) => {
        if (curr.state === s) {
          return (prev += Number(curr.f5));
        }
        return prev;
      }, 0);
    });
    const food6CollectedByState = states.map((s) => {
      return amountCollectedByBazar.reduce((prev, curr) => {
        if (curr.state === s) {
          return (prev += Number(curr.f6));
        }
        return prev;
      }, 0);
    });
    const food7CollectedByState = states.map((s) => {
      return amountCollectedByBazar.reduce((prev, curr) => {
        if (curr.state === s) {
          return (prev += Number(curr.f7));
        }
        return prev;
      }, 0);
    });
    const food8CollectedByState = states.map((s) => {
      return amountCollectedByBazar.reduce((prev, curr) => {
        if (curr.state === "Selangor" && curr.total > 0) {
          // console.log(
          //   `${curr.title} - ${Number(curr.total).toFixed(2)} KG (${
          //     curr.activeDays
          //   } hari)`
          // );
        }
        if (curr.state === s) {
          return (prev += Number(curr.f8));
        }
        return prev;
      }, 0);
    });
    const drinksCollectedByState = states.map((s) => {
      return amountCollectedByBazar.reduce((prev, curr) => {
        if (curr.state === s) {
          return (prev += Number(curr.d));
        }
        return prev;
      }, 0);
    });
    // console.log(
    //   RINGGIT.format(
    //     food1CollectedByState[4] * 19.25 +
    //       food2CollectedByState[4] * 22.0 +
    //       food3CollectedByState[4] * 18.33 +
    //       food4CollectedByState[4] * 16.5 +
    //       food5CollectedByState[4] * 33.0 +
    //       food6CollectedByState[4] * 55.0 +
    //       food7CollectedByState[4] * 26.4 +
    //       food8CollectedByState[4] * 22.0 +
    //       drinksCollectedByState[4].toFixed(2) * 6.0
    //   )
    // );
  }, [amountCollectedByBazar]);

  // state chart
  React.useEffect(() => {
    const amountCollectedByState = states.map((s) => {
      return amountCollectedByBazar.reduce((prev, curr) => {
        if (curr.state === s) {
          return (prev += Number(curr.total));
        }
        return prev;
      }, 0);
    });
    const foodCollectedByState = states.map((s) => {
      return amountCollectedByBazar.reduce((prev, curr) => {
        if (curr.state === s) {
          return (prev += Number(curr.f));
        }
        return prev;
      }, 0);
    });
    const drinksCollectedByState = states.map((s) => {
      return amountCollectedByBazar.reduce((prev, curr) => {
        if (curr.state === s) {
          return (prev += Number(curr.d));
        }
        return prev;
      }, 0);
    });
    const bazars = states.map((s) => {
      const count = amountCollectedByBazar.reduce((prev, curr) => {
        if (curr.state === s) {
          return prev + 1;
        }
        return prev;
      }, 0);
      return `${s} (${count} bazars)`;
    });
    const colors = generateRandomColors(states.length);
    const ctx = document.getElementById("stateChart");
    if (ctx) {
      new Chart(ctx, {
        type: "bar",
        data: {
          labels: states,
          datasets: [
            {
              label: "Kutipan Makanan (KG)",
              data: foodCollectedByState.map((a) => Number(a).toFixed(2)),
              backgroundColor: colors.map((c) => c.replace("1)", "0.7")),
            },
            {
              label: "Kutipan Minuman (KG)",
              data: drinksCollectedByState.map((a) => Number(a).toFixed(2)),
              backgroundColor: colors.map((c) => c.replace("1)", "0.4")),
            },
            {
              label: "Kutipan Makanan & Minuman (KG)",
              data: amountCollectedByState.map((a) => Number(a).toFixed(2)),
              backgroundColor: colors,
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Kutipan Makanan & Minuman (KG)",
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    }
  }, [amountCollectedByBazar]);

  return (
    <div className="body_wrapper">
      <div className="event_img align-items-right">
        <img
          width="100%"
          src={require("../img/attend/mysavefood2.jpeg")}
          alt=""
        />
      </div>
      <div>
        <div className="container text-center mt-3 mb-3">
          <div className="row">
            <div className="col-md-4">
              <Link to="/collection/msf">Maklumat Kutipan</Link>
            </div>
            <div className="col-md-4">
              <Link to="/volunteer/msf">Maklumat Sukarelawan</Link>
            </div>
            <div className="col-md-4">
              <Link to="/map/msf">Peta Kutipan</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center">
        {isLoading && (
          <>
            <CircularProgress />
            <p>Please wait while map are loading...</p>
          </>
        )}
      </div>
      <div className="container">
        <div className="mt-5">
          <h3 className="text-center">Kutipan Mengikut Negeri</h3>
        </div>
        <div className="mb-5">
          <canvas id="stateChart" />
        </div>
      </div>
      <div>
        <MapContainer bazarList={amountCollectedByBazar} />
      </div>
      <Footer FooterData={FooterData} />
    </div>
  );
};

export default MsfMap;
