import React from "react";
import { Redirect } from "react-router-dom";
import qs from "query-string";
import moment from "moment";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
import ScheduleTab from "../components/Event/ScheduleTab";
import { getActivityById } from "../api/activity";
import { trackEvent, PageEventName } from "../services/analytics";

const Attend = () => {
  const [event, setEvent] = React.useState({});
  const [isLoading, setLoading] = React.useState(true);

  React.useEffect(() => {
    trackEvent(PageEventName.Attendance);
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      const { id } = qs.parse(window.location.search);
      const result = await getActivityById(id);
      if (result) {
        // check today date and time is between start and end date time
        // const today = moment();
        // const startDate = moment(result.startDate);
        // const endDate = moment(result.endDate);
        // if (today.isBetween(startDate, endDate)) {
        //   setEvent(result);
        // }
        setEvent(result);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div className="flex_column div_centered">
        <lottie-player
          src="https://assets5.lottiefiles.com/packages/lf20_a2chheio.json"
          background="transparent"
          speed="1.2"
          style={{ width: 120 }}
          loop
          autoplay
        />
        <h2>Finding Activity</h2>
      </div>
    );
  }

  if (event && event.id) {
    return (
      <div className="body_wrapper">
        <div className="event_img align-items-right">
          <img
            width="100%"
            src={require("../img/attend/sukarelawan.png")}
            alt=""
          />
        </div>
        <div className="container mt_70 mb_70">
          <h1 style={{ fontSize: 48 }}>{event.name}</h1>
          <div className="d-flex mt-3 mb-3">
            <img src={require("../img/home-event/marker.png")} alt="" />
            <h4 className="mv_30 mt-2">{event.location}</h4>
          </div>
          <div className="d-flex mt-3 mb-3">
            <img src={require("../img/home-event/timer.png")} alt="" />
            <h6 className="mv_30 mt-2">{`${moment(event.startDate).format(
              "DD MMMM yy (h:mm a)"
            )} - ${moment(event.endDate).format("DD MMMM yy (h:mm a)")}`}</h6>
          </div>
        </div>
        <div className="container mb_70">
          <ScheduleTab activityId={event.id} activityName={event.name} />
        </div>
        <Footer FooterData={FooterData} />
      </div>
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: "/404",
        }}
      />
    );
  }
};

export default Attend;
