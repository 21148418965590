import React from "react";
import { CircularProgress, Paper } from "@material-ui/core";
import EventDialog from "./EventDialog";
import { getEventByEventId, completeEnrollment } from "../../api/event";
import qs from "query-string";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";

const EventDetails = () => {
  const [open, setOpen] = React.useState(false);
  const [event, setEvent] = React.useState({});
  const [isLoading, setLoading] = React.useState(true);
  const [success, setSuccess] = React.useState(false);
  const [enrollmentId, setEnrollmentId] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      const { id, success, enrollment } = qs.parse(window.location.search);
      const result = await getEventByEventId(id || 1);
      setSuccess(success);
      setEvent(result);
      setLoading(false);
      if (success && enrollment) {
        setEnrollmentId(enrollment);
      }
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    const updateData = async () => {
      const result = await completeEnrollment(enrollmentId);
      console.log(result);
    };
    if (enrollmentId) {
      updateData();
    }
  }, [enrollmentId]);

  return (
    <section>
      {event && event.id && event.eventStatus !== 2 ? (
        <>
          <div className="container">
            <EventDialog
              id={event.id}
              title={event.name}
              open={open}
              setOpen={setOpen}
              setSucces={setSuccess}
              status={event.eventStatus}
              fee={Number(event.fee)}
              memberDiscount={Number(event.memberDiscount)}
              customFields={event.customFields}
            />
          </div>
          <div className="container">
            <div className="event_img align-items-right">
              <img
                width="1024"
                src={require("../../img/home-event/promo.png")}
                alt=""
              />
            </div>
            <div className="row event_promotion_info align-items-center">
              <div className="col-md-12">
                <div className="e_promo_text wow fadeInDown row">
                  <div className="col-md-6">
                    <img
                      className="mb-3"
                      width="75"
                      src={require("../../img/logo.png")}
                      alt="logo"
                    />
                    <br />
                    <span className="date">
                      {`${moment(event.startDate).format(
                        "Do MMMM YYYY"
                      )} - ${moment(event.endDate).format("Do MMMM YYYY")}`}
                    </span>
                    <h3>{event.name}</h3>
                    {success ? (
                      <div className="text-center mb_70">
                        <img
                          src={require("../../img/utils/sucess.png")}
                          width="175"
                          alt=""
                        />
                        <p>
                          <strong>Pendaftaran Berjaya</strong>
                        </p>
                        {event.registrationNote && (
                          <div style={{ fontSize: 16 }}>
                            {ReactHtmlParser(event.registrationNote)}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="row">
                        <button
                          onClick={() => setOpen(true)}
                          className={`event_btn btn_hover mb_15`}
                        >
                          Daftar
                        </button>
                        <a href="/" style={{ color: "black", marginTop: -15 }}>
                          <br />
                          Kembali Laman Utama
                          <i className="ti-arrow-right ml-2"></i>
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 text-center mt-5">
                    {event.posterUrl && (
                      <img src={event.posterUrl} alt="" width="225" />
                    )}
                  </div>
                </div>
              </div>
              <Paper className="mt-2 p-4 w-100">
                <div className="col-md-12">
                  <div className="row">
                    <span>
                      <h6>Maklumat Pogram</h6>
                    </span>
                  </div>
                  <div className="row mb-5">
                    <div style={{ fontSize: 18 }}>
                      {ReactHtmlParser(event.description)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <span>
                        <span>
                          <img
                            src={require("../../img/home-event/marker.png")}
                            alt=""
                          />
                          <h6>Tempat</h6>
                          {event.venue}
                        </span>
                      </span>
                    </div>
                    <div className="col-md-6">
                      <span>
                        <span>
                          <img
                            src={require("../../img/home-event/timer.png")}
                            alt=""
                          />
                          <h6>Masa</h6>
                          {`${moment(event.startDate).format(
                            "h:mm a"
                          )} - ${moment(event.endDate).format("h:mm a")}`}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="row mt-5 mb-5">
                    <span>
                      <h6>Yuran Penyertaan</h6>{" "}
                      {Number(event.fee) === 0 ? "Percuma" : `RM${event.fee} `}
                      {Number(event.memberDiscount) > 0 &&
                        `(Ahli: RM${(
                          Number(event.fee) -
                          (Number(event.memberDiscount) / 100) *
                            Number(event.fee)
                        ).toPrecision(2)})`}
                    </span>
                  </div>
                </div>
              </Paper>
            </div>
          </div>
        </>
      ) : (
        <div className="container d-flex">
          {isLoading ? (
            <CircularProgress />
          ) : (
            <div>
              <img
                className="mh_50"
                src={require("../../img/utils/fail.png")}
                alt=""
                width="350"
              />
              <p>EVENT NOT EXIST OR HAS ENDED</p>
            </div>
          )}
        </div>
      )}
    </section>
  );
};
export default EventDetails;
