import React, { Component } from "react";
import Reveal from "react-reveal/Reveal";
import { trackEvent, ButtonEventName } from "../../services/analytics";

class PaymentBanner extends Component {
  render() {
    return (
      <>
        <section className="payment_banner_area">
          <div className="shape one"></div>
          <div className="shape two"></div>
          <div className="container">
            <img src={require("../../img/logo.png")} alt="logo" />
            <Reveal effect="fadeInLeft" duration={500}>
              <div
                className="payment_banner_content wow fadeInLeft"
                data-wow-delay="0.4s"
              >
                <h1 className="f_p f_700 f_size_50 w_color">
                  Pertubuhan Pemuda GEMA Malaysia
                </h1>
                <p className="w_color f_p f_size_16">
                  Organisasi anak muda yang berusaha menjana perubahan positif
                  dalam kehidupan generasi muda supaya mereka berupaya menjadi
                  ejen perubahan kepada pembangunan keluarga, komuniti,
                  masyarakat dan negara.
                </p>
                <div className="action_btn d-flex align-items-center mt_60">
                  <a
                    href="/register"
                    onClick={() => trackEvent(ButtonEventName.Register)}
                    className="btn_hover agency_banner_btn"
                  >
                    Daftar Ahli Baru
                  </a>
                  <a href="/verify" className="agency_banner_btn_two">
                    Semak Keahlian<i className="ti-arrow-right"></i>
                  </a>
                  <a href="/renew" className="btn_hover agency_banner_btn_two">
                    Perbaharui Keahlian<i className="ti-arrow-right"></i>
                  </a>
                </div>
              </div>
            </Reveal>
          </div>
          <Reveal effect="fadeInLeft" duration={1200}>
            <div
              className="animation_img_two wow fadeInRight"
              data-wow-delay="0.5s"
            >
              <lottie-player
                src="https://assets10.lottiefiles.com/packages/lf20_KO70yq.json"
                background="transparent"
                speed="0.2"
                style={{ width: 600, height: 550 }}
                loop
                autoplay
              />
            </div>
          </Reveal>
          <img
            className="svg_intro_bottom"
            src={require("../../img/home9/shape.png")}
            alt=""
          />
        </section>
      </>
    );
  }
}
export default PaymentBanner;
