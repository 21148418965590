import axios from "axios";
import moment from "moment-timezone";

const token = process.env.REACT_APP_MAIN_API_TOKEN;

const mainClient = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_MAIN_API_URL
      : process.env.REACT_APP_MAIN_API_URL_LOCAL,
  headers: {
    "X-Request-Agent": "gema-dashboard",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export async function createNewAttendance(values, activityId) {
  const attendanceDto = {
    activityId,
    nationalId: values.nationalId,
    attendee: {
      name: values.name,
      email: values.email,
      phone: values.phone,
    },
  };
  try {
    const response = await mainClient.post("/attendance", attendanceDto);
    if (response.status === 201) {
      return true;
    }
  } catch (error) {
    return false;
  }
}

export async function createRegisteredAttendance(attendanceDto) {
  try {
    const response = await mainClient.post(
      "/attendance/registered",
      attendanceDto
    );
    if (response.status === 201) {
      return { created: true };
    }
    return { created: false, data: response.data };
  } catch ({ response }) {
    return {
      created: false,
      data: response?.data || "An unexpected error occurred",
    };
  }
}

export async function confirmAttendedUser(confirmedUser) {
  try {
    const response = await mainClient.put("/attendance/confirmation", {
      confirmedUser,
      endTime: moment().tz("Asia/Kuala_Lumpur").toDate(),
    });
    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    return false;
  }
}
