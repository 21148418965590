import axios from "axios";

const token = process.env.REACT_APP_MAIN_API_TOKEN;

const memberAxios = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_MAIN_API_URL
      : process.env.REACT_APP_MAIN_API_URL_LOCAL,
  headers: {
    "X-Request-Agent": "gema-web",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export async function createNewMember(memberDto) {
  try {
    const response = await memberAxios.post("/member", memberDto);
    if (response.status === 201) {
      const {
        email,
        fullname,
        branch,
        memberType,
        memberStatus,
      } = response.data;
      const createBillDto = {
        email,
        name: fullname,
        branch,
        memberType,
        memberStatus,
      };
      const { data: url } = await memberAxios.post(
        "/payment/billplz",
        createBillDto
      );
      console.log(url);
      return url;
    }
    return false;
  } catch (error) {
    throw error.response.data;
  }
}

export async function checkMembership(membershipDto) {
  try {
    const response = await memberAxios.post(
      "/member/legacy/membership",
      membershipDto
    );
    if (response.status === 200) {
      return response.data;
    }
    return { id: null, fail: true };
  } catch (error) {
    console.log(error);
    return { id: null };
  }
}

export async function getMemberDetails(membershipDto) {
  try {
    const response = await memberAxios.post(
      "/member/memberdetails",
      membershipDto
    );
    if (response.status === 201) {
      return response.data;
    }
    return { id: null, fail: true };
  } catch (error) {
    console.log(error);
    return { id: null };
  }
}

export async function renewMember(id, memberDto) {
  try {
    const response = await memberAxios.put(`/member/${id}`, memberDto);
    if (response.status === 200) {
      const {
        email,
        fullname,
        branch,
        memberType,
        memberStatus,
      } = response.data;
      const createBillDto = {
        email,
        name: fullname,
        branch,
        memberType,
        memberStatus,
      };
      const { data: url } = await memberAxios.post(
        "/payment/billplz",
        createBillDto
      );
      console.log(url);
      return url;
    }
    return false;
  } catch (error) {
    throw error.response.data;
  }
}

export async function updateBranch(id, branch) {
  try {
    const response = await memberAxios.put(
      `/member/branch/change/${id.replace("G", "")}`,
      { branch }
    );
    if (response.status === 200) {
      return response.data;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function createNewPayment(memberDto) {
  try {
    const { data: url } = await memberAxios.post(
      "/payment/membership-fee",
      memberDto
    );
    console.log(url);
    return url;
  } catch (error) {
    throw error.response.data;
  }
}
