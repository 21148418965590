import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { createSub } from "../api/subscription";
import { useWindowSize } from "../hooks/useWindowSize";
import stripe from "../img/payment/stripe-logo.svg";

import CheckoutForm from "./CheckoutForm";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const TrackingPrice = () => {
  let subtitle;
  const [isLoading, setIsLoading] = React.useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [amount, setAmount] = useState(10);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  const size = useWindowSize();

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  const createSubscritpion = async () => {
    setIsLoading(true);
    try {
      const data = await createSub({ name, email, amount });
      setClientSecret(data.clientSecret);
      openModal();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      alert("Something when wrong. Please contact support!");
    }
  };

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#000";
    subtitle.style.textAlign = "center";
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <section id="donateNow" className="tracking_price_area sec_pad">
      <div className="container">
        <div>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <p style={{ textAlign: "center" }}>
              Sumbangan Impak Lestari GEMA <br /> RM {amount}.00 Sebulan
            </p>
            <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
              Pembayaran Kad Debit/Kredit
            </h2>
            <div className="App">
              {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm closeModal={closeModal} />
                </Elements>
              )}
            </div>
            <div style={{ textAlign: "center", marginTop: 10 }}>
              <img width={150} src={stripe} alt="stripe" />
            </div>
          </Modal>
        </div>
        <div className="tracking_price_inner wow fadeInUp">
          <h2>
            SUMBANGAN <br />
            IMPAK LESTARI GEMA
          </h2>
          <div className="d-flex justify-content-center track_body">
            <div className="track_price">
              <p>
                Nama:{" "}
                <input
                  style={{ width: "275px" }}
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </p>
              <p>
                E-mel:{" "}
                <input
                  style={{ width: "275px" }}
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </p>
              <h3 style={{ fontSize: size.width < 700 ? "50px" : "55px" }}>
                RM{" "}
                <input
                  style={{
                    width: size.width < 700 ? "100px" : "130px",
                    textAlign: "right",
                  }}
                  type="number"
                  min={10}
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                .00
              </h3>
              <h6 style={{ fontSize: size.width < 700 ? "18pt" : "24pt" }}>
                <span
                  style={{
                    color: "red",
                    fontSize: size.width < 700 ? "18pt" : "24pt",
                  }}
                >
                  {amount < 10 && "Minimum RM10 "}
                </span>
                sebulan
              </h6>
            </div>
          </div>
          <div className="text-center">
            <button
              disabled={!name || !email || isLoading || amount < 10}
              onClick={createSubscritpion}
              target="__blank"
              className="software_banner_btn btn_submit f_500"
            >
              {isLoading ? "Loading..." : "Sumbang Sekarang"}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrackingPrice;
