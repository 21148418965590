import React, { Component } from "react";
import ErpIconitem from "./ErpIconitem";
import Slider from "react-slick";

class ErpFeaturesitem extends Component {
  render() {
    let { rowClass /*image*/ } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      speed: 2000,
      fade: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className={`row erp_item_features ${rowClass}`}>
        <div className="col-lg-6">
          <div className="erp_features_img_two">
            <Slider className="app_screenshot_slider" {...settings}>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/heroes/h2.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/heroes/h3.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/heroes/h5.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/heroes/h1.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/heroes/h7.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/heroes/h8.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="item">
                <div className="screenshot_img">
                  <img
                    src={require("../../img/passion-clusters/heroes/h6.png")}
                    alt=""
                  />
                </div>
              </div>
            </Slider>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="erp_content_two">
            <div className="hosting_title erp_title">
              <h2>HEROES</h2>
              <p>
                HEROES merupakan satu platform yang memberi ruang kepada belia
                Malaysia membina jati diri melalui aktiviti kesukarelawan.{" "}
                <br />
                Sehingga ke hari ini, sekarelawan HEROES telah terlibat dalam
                menjayakan pelbagai misi bantuan kepada masyarakat yang
                memerlukan.
              </p>
            </div>
            <ErpIconitem 
              iconName="icon_ribbon_alt" 
              erpText="Hugs 4 HEROES" />
            <ErpIconitem
              iconName="icon_ribbon_alt"
              erpText="Skuad Banjir HEROES"
            />
            <ErpIconitem
              iconName="icon_ribbon_alt"
              erpText="Tabung Misi: Fundraising"
            />
            <ErpIconitem 
              iconName="icon_ribbon_alt" 
              erpText="Tuisyen Ikhlas" />
            <ErpIconitem
              iconName="icon_ribbon_alt"
              erpText="Projek Kita Kawan & Hero Perpaduan"
            />
            {/* <ErpIconitem
              iconName="icon_ribbon_alt"
              erpText="PUBG Unity League"
            /> */}
            {/* <a href="https://linktr.ee/ProfileGEMA" target="__blank" className="erp_btn_learn">Ketahui Lebih Lanjut<i className="arrow_right"></i></a> */}
          </div>
        </div>
      </div>
    );
  }
}
export default ErpFeaturesitem;
