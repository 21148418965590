import React from "react";
import TrackingBanner from "../components/Banner/TrackingBanner";
import TrackingPrice from "../components/TrackingPrice";
import Footer from "../components/Footer/Footer";
import FooterData from "../components/Footer/FooterData";
import ErpFeatures from "../components/Features/ErpFeatures";
import ChatClients from "../components/ChatClients";
// import StartupFeatures from "../components/Features/StartupFeatures";
import SecurityCustomerLogo from "../components/SecurityCustomersLogo";
import SubscribeDonation from "../components/SubscribeDonation";
import { trackEvent, PageEventName } from "../services/analytics";

const Invest = () => {
  React.useEffect(() => {
    trackEvent(PageEventName.Donation);
  }, []);

  return (
    <div className="body_wrapper">
      <TrackingBanner />
      <SubscribeDonation />
      <ErpFeatures />
      <SecurityCustomerLogo />
      <TrackingPrice />
      <ChatClients />
      <Footer FooterData={FooterData} />
    </div>
  );
};
export default Invest;
