import axios from "axios";

const token = process.env.REACT_APP_MAIN_API_TOKEN;

const mainAPIClient = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_MAIN_API_URL
      : process.env.REACT_APP_MAIN_API_URL_LOCAL,
  headers: {
    "X-Request-Agent": "gema-web",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export async function getActivityById(id) {
  try {
    const { data } = await mainAPIClient.get(`/attendance/activity/${id}`);
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
}
